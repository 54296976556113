import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {ExternalLink} from "../helpers/EasyLinks";
import './SponsorsPanel.css'

class SponsorsPanel extends Component {
    render() {
        const simple = this.props.simple;

        const frontpageSponsors = this.props.sponsors.filter(group => group.frontpage);
        const agendaSponsors = this.props.sponsors.filter(group => group.showOnAgenda)
        let showingSponsors = simple ? agendaSponsors : frontpageSponsors;
        return (
            <div className={"sponsors-panel" + (simple ? ' simple' : '')}>
                {showingSponsors.map(group => {
                    const groupName = group.name + (group.sponsors.length > 1 ? 's' : '');
                    return <div key={group.key}
                                className={"sponsors sponsor-" + group.key}>
                        <h2>{groupName}</h2>
                        <div className="sponsor-group">
                            {group.sponsors.map((sponsor, index) => (
                                <div key={group.key + index} className={group.key + "-sponsor sponsor"}>
                                    <ExternalLink link={sponsor.url} key={sponsor.key}>
                                        <figure>
                                            {sponsor.image &&
                                            <img className={group.key + '-img ' + (simple ? '' : 'hover')}
                                                 src={sponsor.image} alt={sponsor.company}/>}
                                            {!sponsor.image && <h3>{sponsor.company}</h3>}
                                        </figure>
                                    </ExternalLink>
                                </div>
                            ))}
                            {simple && <hr/>}
                        </div>
                    </div>
                })}
                {showingSponsors.length === 0 && <div>
                    <h2>Become a sponsor</h2>
                    <p>Check out our <Link to="sponsors">Sponsor page</Link> for  more information.</p>
                </div>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sponsors: state.sponsors,
        hasErred: state.sponsorsHasErred
    };
};

export const FeaturedSponsors = connect(mapStateToProps)(SponsorsPanel);
