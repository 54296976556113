import {TAGS_HAS_ERRED, TAGS_IS_LOADING, TAGS_FETCHED_OK, TAGS_SET_ACTIVE} from '../constants/tags'
import {tagsUrl} from "../../config";

export const tagsHasErred = (bool) => ({
    type: TAGS_HAS_ERRED,
    hasErred: bool
});

export const tagsIsLoading = (bool) => ({
    type: TAGS_IS_LOADING,
    isLoading: bool
});

export const tagsFetchOk = (tags) => ({
    type: TAGS_FETCHED_OK,
    tags: tags
});

export const setActiveTags = (activeTags) => ({
    type: TAGS_SET_ACTIVE,
    activeTags: activeTags
});

const sortTags = (tags) => tags.sort((tag1, tag2) => tag2.talks.length - tag1.talks.length);

export const tagsFetchData = () => {
    return (dispatch) => {
        dispatch(tagsIsLoading(true));

        window.setTimeout(() => (fetch(tagsUrl)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(tagsIsLoading(false));

                return response;
            })
            .then(response => response.json())
            .then(tags => sortTags(tags))
            .then(tags => dispatch(tagsFetchOk(tags)))
            .catch(() => dispatch(tagsHasErred(true)))), 100);
    };
};

