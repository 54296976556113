import React, {Component} from 'react';
import { PagePanel } from "../../components/helpers/PagePanel";
import {SponsorView} from "./SponsorView";

export class SponsorsList extends Component {
    render() {
        return (
            <div className="sponsorsList">
                {this.props.sponsors.map(group => {
                    const groupName = group.name + (group.sponsors.length > 1 ? 's' : '');
                    return <PagePanel key={group.key}>
                        <h2>{groupName}</h2>
                        {group.sponsors.map(sponsor => (
                            <SponsorView {...sponsor} group={group} list key={sponsor.key}/>
                        ))}
                    </PagePanel>
                })}
            </div>
        )
    }
}

