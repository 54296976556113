// See https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
import queryString from 'query-string'

export const get = (url, data) => {
    return fetch(url + '?' + queryString.stringify(data) , {
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
        },
        method: 'GET',
        mode: 'cors',
    })
};

export const post = (url, data) => {

    return fetch(url, {
        body: queryString.stringify(data),
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded'
        },
        method: 'POST',
        mode: 'cors',
    })
};