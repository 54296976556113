import React, {Component} from 'react';
import Event from './fragments/Event';
import About from './fragments/About';
import {Pricing} from './fragments/Prices';
import './Home.css'
import {Page, PagePanel} from "../../components/helpers/PagePanel";
import {FeaturedSponsors} from "../../components/sponsors/SponsorsPanel";
import {showSpeakers} from "../../config";
import FeaturedSpeakers from "../../components/speakers/FeaturedSpeakers";


class Home extends Component {

    render() {
        return (
            <Page className="Home">
                <Event/>

                <About/>
                <Pricing/>
                {showSpeakers && <FeaturedSpeakers/>}
                <PagePanel className="transparent">
                    <FeaturedSponsors/>
                </PagePanel>
            </Page>
        );
    }

}

export default Home;

