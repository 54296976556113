import {SPONSORS_HAS_ERRED, SPONSORS_FETCHED_OK} from '../constants/sponsors'
import {sponsorDataUrl} from "../../config";

export const sponsorsHasErred = (bool) => ({
    type: SPONSORS_HAS_ERRED,
    hasErred: bool
});

export const sponsorsFetchOk = (sponsors) => ({
    type: SPONSORS_FETCHED_OK,
    sponsors
});


export const sponsorsFetchData = () => {
    return (dispatch) => {

        fetch(sponsorDataUrl)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response.json())
            .then((sponsors) => {
                const filteredSponsors = sponsors.map(group => {
                    const activeSponsors = group.sponsors
                        .filter(sponsor => (sponsor.active))
                        .map(sponsor => (
                            {
                                ...sponsor,
                                // imageUrl: '/sponsor-data/' + sponsor.key + '.svg',
                                // htmlUrl: '/sponsor-data/' + sponsor.key + '.html'
                            }));
                    return {...group, sponsors: activeSponsors}
                }).filter(group => (group.sponsors.length > 0));

                dispatch(sponsorsFetchOk(filteredSponsors))
            })
            .catch(() => dispatch(sponsorsHasErred(true)));
    }
};
