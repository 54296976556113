import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FA from 'react-fontawesome'
import {ExternalLink} from "../helpers/EasyLinks";
import {Link} from "react-router-dom";
import './Speaker.css'

export default class Speaker extends Component {
    static propTypes = {
        speaker: PropTypes.object.isRequired,
        detailed: PropTypes.bool
    };

    render() {
        const {name, image, company, bio, talks} = this.props.speaker;
        const detailed = this.props.detailed;
        const twitter = this.props.speaker.twitter.replace(/@/, '');
        const bioHtml = {__html: bio || "<div/>"};
        const size = detailed ? 150 : 200;
        return (
            <div className="speaker" style={{marginTop: 10}}>
                <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                    <Link to={"/speakers/" + twitter}>
                        <figure><img className="img-thumbnail" style={{width: size, height: size}} src={image} alt={name}/></figure>
                    </Link>
                </div>
                <div style={{marginLeft: 10, display: 'inline-block', minWidth: '50%'}}>
                    <h4>
                        <Link to={"/speakers/" + twitter}>
                            {name}
                        </Link>
                    </h4>
                    <h5>{company}</h5>
                    <ExternalLink link={"https://twitter.com/" + twitter}><FA name="twitter"/> {twitter}</ExternalLink>
                </div>
                {detailed && <div className="bio">
                    <div className="well" style={{marginTop: 10}} dangerouslySetInnerHTML={bioHtml}/>
                    <div>
                        <h4>Presents:</h4>
                        <ul>
                            {talks.map(talk => (
                                <li key={talk.id}><Link to={"/talks/"+talk.id}>{talk.title}</Link></li>
                            ))}
                        </ul>
                    </div>
                </div>}
            </div>
        );
    }
}
