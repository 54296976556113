import {TALKS_HAS_ERRED, TALKS_IS_LOADING, TALKS_FETCHED_OK} from '../constants/talks'

export const talks = (state = [], action) => {
    switch (action.type) {
        case TALKS_FETCHED_OK:
            return action.talks;
        default:
            return state;
    }
};

export const talksHasErred = (state = false, action) => {
    switch (action.type) {
        case TALKS_HAS_ERRED:
            return action.hasErred;

        default:
            return state;
    }
};

export const talksIsLoading = (state = false, action) => {
    switch (action.type) {
        case TALKS_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
};

