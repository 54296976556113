import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {RenderSpeakersLinks} from "../speakers/SpeakersLink";
import Favorite from "./Favorite";
import Rating from "./Ratings";
import {findSpeakers} from "../../data/helpers/talks";
import {connect} from "react-redux";
import FA from 'react-fontawesome';
import {ExternalLink} from "../helpers/EasyLinks";

class TalkInfo extends Component {

    static propTypes = {
        talk: PropTypes.object.isRequired,
        isLoading: PropTypes.bool,
        speakersById: PropTypes.object,
        detailed: PropTypes.bool
    };

    render() {
        const {talk, speakersById, isLoading} = this.props;
        const {id, title, summary, tags, media} = talk; // , level, slots
        const detailed = this.props.detailed;
        const bioHtml = {__html: summary || "<div/>"};
        const speakers = findSpeakers(talk, speakersById);

        const listMedia = () => (
            media && media.length > 0 && <div>
                <h4>Slides, videos and more</h4>
                <p>
                   {media.map(m => (
                       <span style={{paddingRight: 10}}><ExternalLink link={m.url}><FA name={m.icon} size="2x"/></ExternalLink></span>
                   ))}
                </p>
                </div>
        );

        return <div style={{marginTop: 10}}>
            {isLoading && <div>Loading talk</div>}
            {!isLoading && !detailed && <div style={{marginLeft: 5, display: 'inline-block'}}>
                <h4> <Favorite id={id}/> <Rating id={id}/> <Link
                    to={"/talks/" + id}>{title}</Link> &nbsp;
                    <small className="presented-by">Presented by:
                        <RenderSpeakersLinks speakers={speakers}/>
                    </small>
                </h4>
                <p>{tags.map(tag => (<span key={tag}><span className="badge">{tag}</span> </span>))}</p>
            </div>}
            {!isLoading && detailed && <div className="summary">
                <p className="text-right"><Favorite id={id}/> <Rating id={id}/></p>
                <div className="well" style={{marginTop: 10}} dangerouslySetInnerHTML={bioHtml}/>
                <p>{tags.map(tag => (<span key={tag}><span className="badge">{tag}</span> </span>))}</p>
                {listMedia()}
                <div>
                    <h4>Presented by:</h4>
                    {speakers.map(speaker => (
                        <div key={speaker.id} style={{paddingTop: 5}}>
                            <img className="img-rounded" style={{maxHeight: '30px', display: 'inline'}}
                                 src={speaker.image} alt={speaker.name}/>
                            <div style={{display: 'inline', paddingLeft: 5}}><Link
                                to={"/speakers/" + speaker.twitter}>{speaker.name}</Link></div>
                        </div>
                    ))}
                </div>
            </div>}
        </div>
    };

}


const mapStateToProps = (state) => ({
    isLoading: state.speakersIsLoading || state.talksIsLoading,
    speakersById: state.speakersById
});

export default connect(mapStateToProps)(TalkInfo);
