import {OAUTH_CODE, OAUTH_CODE_CHECKING, OAUTH_CODE_STATUS, OAUTH_USER_AUTHORIZED} from '../constants/user'

export const oauth = (state = [], action) => {
    switch (action.type) {
        case OAUTH_CODE:
            return {...state, code: action.code};
        case OAUTH_CODE_STATUS:
            return {...state, fetchingCode: action.fetchingCode};
        case OAUTH_CODE_CHECKING:
            return {...state, checkingCode: action.checkingCode};
        case OAUTH_USER_AUTHORIZED:
            const user = action.user;
            if (user.status === 200 || user.status === 412) {
                localStorage.setItem('user', JSON.stringify(user))
            } else {
                localStorage.removeItem('user');
            }
            return {...state, user: user, isLoggedIn: user.status === 200, needTwitterInfo: user.status === 412 };
        default:
            return state;
    }
};

// export const pricesHasErred = (state = false, action) => {
//     switch (action.type) {
//         case OAUTH_CODE_STATUS:
//             return action.hasErred;
//
//         default:
//             return state;
//     }
// };
//
// export const pricesIsLoading = (state = false, action) => {
//     switch (action.type) {
//         case OAUTH_CODE_CHECKING:
//             return action.isLoading;
//
//         default:
//             return state;
//     }
// };

