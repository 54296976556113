import React from 'react';
import "./PagePanel.css";

export const MailTo = (props) => (
    <a className={props.className} style={props.style} href={'mailto:' + props.email} target="_blank"
        rel="noopener noreferrer">
        {props.children || props.email}
    </a>);

export const ExternalLink = (props) => (
    <a href={props.link} target="_blank" rel="noopener noreferrer" className={props.className} style={props.style}>
        {props.children || props.link}
    </a>);
