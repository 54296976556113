import {TAGS_HAS_ERRED, TAGS_IS_LOADING, TAGS_FETCHED_OK, TAGS_SET_ACTIVE} from '../constants/tags'

export const tags = (state = [], action) => {
    switch (action.type) {
        case TAGS_FETCHED_OK:
            return {
                all: action.tags,
                active: []
            };
        case TAGS_SET_ACTIVE:
            return {...state, active: action.activeTags} ;
        default:
            return state;
    }
};

export const tagsHasErred = (state = false, action) => {
    switch (action.type) {
        case TAGS_HAS_ERRED:
            return action.hasErred;

        default:
            return state;
    }
};

export const tagsIsLoading = (state = false, action) => {
    switch (action.type) {
        case TAGS_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
};

