import moment from 'moment'

export const timeAsNumber = (date) => date ? date.hour() + date.minute() / 60 : Number.NaN;

export const sameMinute = (date1, date2) => !!date1 && !!date2 && date1.hour() === date2.hour() && date1.minute() === date2.minute();

export const dateStringToMoment = (string) => string ? moment(string, 'YYYY-MM-DD') : undefined;

export const timeStringToMoment = (string) => string ? moment(string, 'HH:mm:ss') : undefined;

export const momentToDateString = (date) => date ? date.format('YYYY-MM-DD') : undefined;

export const timeToInt = (time, part) => time ? parseInt(timeStringToMoment(time).format(part),0) : Number.NaN;

export const timeToMinutes = (time) => timeToInt(time,'HH') * 60 + timeToInt(time, 'mm');

export const calculateDuration = (start, end) => timeToMinutes(end) - timeToMinutes(start);
