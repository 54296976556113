import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Slider from 'react-slick';
import './FeaturedSpeaker.css'
import {Link} from "react-router-dom";
import {PagePanel} from "../helpers/PagePanel";
class FeaturedSpeaker extends Component {
    render() {
        return <Link to={"/speakers/"+this.props.speaker.twitter}>
            <div className="featured-speaker">
            <h2>{this.props.speaker.name}</h2>
            <img className="img-thumbnail" src={this.props.speaker.image} alt={this.props.speaker.name}/>
                <p>{this.props.speaker.shortBio}</p>
        </div>
        </Link>
    }
}

class FeaturedSpeakers extends Component {
    constructor(props) {
        super(props);
        this.settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            autoplay: true
        };
    }

    render() {
        if (this.props.isLoading) {
            return <div/>
        }
        if(this.props.speakers.length === 0) {
            return <div/>
        }
        return (
            <PagePanel className="transparent featured" title="Featured speakers">

                <div className="featured-speakers">
                    <Slider {...this.settings}>
                        {this.props.speakers.map(speaker => (
                            <div key={speaker.id}>
                                <FeaturedSpeaker speaker={speaker}/>
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className="text-center lead">
                    <Link to="/speakers">List all speakers</Link>
                </div>
            </PagePanel>
        );
    }
}

FeaturedSpeakers.propTypes = {
    isLoading: PropTypes.bool,
    speakers: PropTypes.array
};
const mapStateToProps = (state) => ({
    speakers: state.featuredSpeakers, isLoading: state.speakersIsLoading
});

export default connect(mapStateToProps)(FeaturedSpeakers);
