import React from "react";
import {Page, PagePanel} from "../../../components/helpers/PagePanel";
import {ExternalLink, MailTo} from "../../../components/helpers/EasyLinks";
import {Link} from "react-router-dom";

export const CodeOfConduct = () => (
    <Page>
        <PagePanel title="GR8Conf & JDK.io Code of Conduct">
            <p className="lead">
                All attendees, speakers, sponsors and volunteers at our conference are required to agree with the
                following code of conduct. Organisers will enforce this code throughout the event. We expect cooperation
                from all participants to help ensure a safe environment for everybody.
            </p>

            <h2>Need Help?</h2>

            <p>Contact us by email <MailTo email="info@gr8conf.org"/> or get in touch with one of the crew members.</p>

            <h2>The Quick Version</h2>

            <p>
                Our conference is dedicated to providing a harassment-free conference experience for everyone,
                regardless of gender, gender identity and expression, age, sexual orientation, disability, physical
                appearance, body size, race, ethnicity, religion (or lack thereof), or technology choices. We do not
                tolerate harassment of conference participants in any form. Sexual language and imagery is not
                appropriate for any conference venue, including talks, workshops, parties, Twitter and other online
                media. Conference participants violating these rules may be sanctioned or expelled from the
                conference <em>without a refund</em> at the discretion of the conference organisers.
            </p>

            <h2>The Less Quick Version</h2>

            <p>
                Harassment includes offensive verbal comments related to gender, gender identity and expression, age,
                sexual orientation, disability, physical appearance, body size, race, ethnicity, religion, technology
                choices, sexual images in public spaces, deliberate intimidation, stalking, following, harassing
                photography or recording, sustained disruption of talks or other events, inappropriate physical contact,
                and unwelcome sexual attention.
            </p>

            <p>
                Participants asked to stop any harassing behavior are expected to comply immediately.
            </p>

            <p>
                Sponsors are also subject to the anti-harassment policy. In particular, sponsors should not use
                sexualised images, activities, or other material. Booth staff (including volunteers) should not use
                sexualised clothing/uniforms/costumes, or otherwise create a sexualised environment.
            </p>

            <p>
                If a participant engages in harassing behavior, the conference organisers may take any action they deem
                appropriate, including warning the offender or expulsion from the conference with no refund.
            </p>

            <p>
                If you are being harassed, notice that someone else is being harassed, or have any other concerns,
                please contact a member of conference staff immediately. Conference staff can be identified as they will
                be wearing branded clothing and/or badges.
            </p>

            <p>
                Conference staff will be happy to help participants contact hotel/venue security or local law
                enforcement, provide escorts, or otherwise assist those experiencing harassment to feel safe for the
                duration of the conference. We value your attendance.
            </p>

            <p>
                We expect participants to follow these rules at conference and workshop venues and conference-related
                social events.
            </p>

            <p className="small">
                <em>Original source and credit</em>: <ExternalLink
                link="http://2012.jsconf.us/#/about">http://2012.jsconf.us/#/about</ExternalLink>,
                <ExternalLink link="http://geekfeminism.wikia.com/wiki/Conference_anti-harassment/Policy">The Ada
                    Initiative</ExternalLink> and
                <ExternalLink link="http://confcodeofconduct.com/">Code Of Conduct</ExternalLink>
            </p>
        </PagePanel>
    </Page>);

export const PrivacyNoticePage = () => (
    <Page>
        <PagePanel title="Privacy Notice">

            <p className="lead">
                GR8Conf cares deeply about your privacy. We strive to protect the privacy and the
                confidentiality of personal data we process.
            </p>
            <p>
                This is the privacy declaration (hereinafter referred to as ‘Privacy Declaration’) of GR8 ApS, a
                private limitated company under Danish law, with registered office at Buchwaldsgade 50, 5000
                Odense C, and lawfully registered with the Danish Business Authorities under the number 3359
                4992 (hereafter referred to as ‘GR8Conf’, ’we’ or ‘us’).
            </p>
            <p>
                We do everything in our power to comply with the Regulation (EU) 2016/679 of the European Parliament and
                of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of
                personal data and on the free movement of such data (hereafter the ‘Regulation’) or any applicable data
                protection legislation (hereinafter collectively with the Regulation referred to as the ‘Data Protection
                Law’).
            </p>
            <p>
                The words ‘personal data’, ‘controller’, ‘processor’, ‘process’, ‘processing’, ‘profiling’,
                ‘data subject’ and all its derivatives used in this Privacy Declaration shall have the meaning
                set out in the Regulation.
            </p>
            <h2>1. Identity of the controller and contact details</h2>
            <p>
                GR8Conf organizes conferences. We are the controller of the personal data that is used to
                organize these conferences.
            </p>
            <p>
                Where GR8Conf is deemed to be the controller, GR8Conf will comply with this Privacy Declaration.
            </p>
            <h2>2. The personal data we collect and process</h2>
            <p>
                We process several categories of personal data, for instance identification data (e.g. name,
                surname, employer,…) and contact data (e.g. address, email address) financial data (e.g. bank
                account number, VAT number). These personal data may relate to you in your capacity as a
                customer or supplier of our company, but also to you as a business relationship of one of our
                clients, and/or as one of the attendees of our conferences.
            </p>
            <p>
                We collect and process personal data that you provide to us directly (e.g. by registering online
                for one of our conferences), as well as data that we obtain through another route, for example
                via a public source or provided by one of our clients or sponsors.
            </p>
            <p>
                We also use cookies for our website. More information about this can be found <Link
                to="/cookies">here</Link>. </p>
            <p>We do not use automated data processing or so-called <i>“profiling”.</i>
            </p>
            <h2>3. Purposes of and legal basis for the processing personal data</h2>
            <h3>3.1 In General</h3>
            <p>
                We process your personal data for various purposes, including conducting our general and
                financial administration, the organisation of our (future) conferences, to keep you updated
                about our (future) conferences, managing our customer and supplier base, providing a correct
                service and the proper execution of our agreements and direct marketing activities (e.g. sending
                newsletters).
            </p>
            <p>
                Article 6.1 of the Regulation indicates the legal grounds on the basis of which personal data
                may be processed. Below we give you a brief overview of the legal grounds we rely on for the
                processing of personal data.
            </p>
            <p>Your personal data may be processed:</p>
            <ul>
                <li>Based upon your consent: we process personal data based upon your explicit and specific
                    consent. Your consent is freely given and can be withdrawn at any moment.
                </li>
                <li>For compliance with a legal obligation to which we are subject to: We have to comply with
                    various legal obligations, under which we are obliged to maintain specific (personal) data.
                </li>
                <li>For the performance of a contract to which you are a party or in order to take steps at your
                    request prior to entering into a contract: to execute our agreements, it’s necessary to
                    process personal data. We hereby always limit ourselves to processing the data that are
                    strictly necessary.
                </li>
                <li>Based upon our legitimate interests or those of a third party: We may process data to
                    protect our legitimate interests or those of our clients, suppliers or a third party, except
                    where such interests are overridden by your interests or your fundamental rights and
                    freedoms which require protection of your personal data.
                </li>
            </ul>
            <h3>3.2 Attending our conferences</h3>
            <p>
                GR8Conf organizes Java community conferences. Organizing these conferences requires a lot of
                administration. Nevertheless we try to keep it very simple for our attendees.
            </p>
            <h5>(a) Buying an entrance ticket for one of our conferences</h5>
            <p>
                Registering for one of our conferences takes only a few steps. First of all we ask you to fill in
                an online form. In this form we ask you for several types of personal data, like identification
                data (name, surname, company you’re connected to) and contact details (address, email address)
                of the attendee(s). For the avoidance of the doubt, if a third party buys a ticket for someone
                else (e.g. an employer for its employees) we will ask and process data from the attendees for
                whom a ticket will be bought.
            </p>
            <p>
                After you’ve been registered, we will ask you to pay for your entrance ticket(s). Therefore, we
                may ask you for your bank account number, card number, name of the card holder, the due date of
                the card, VAT number,… The processing of these data is a necessary step prior to entering into a
                contract with us. Without the processing of these data you will be unable to attend (one of) our
                conferences.
            </p>
            <h5>(b) Sharing your personal data with the sponsors</h5>
            <p>
                We share the identification data and contact details of the attendees of our conferences with
                the sponsors at our conferences. More information about our sponsor can be found <Link
                to="/sponsors">here</Link>. You can opt out of sharing with our sponsors during the registration
                or by letting us know by email.
            </p>

            <h4>3.3 Direct marketing activities</h4>
            <p>
                We also process personal data for reasons of direct marketing.
            </p>
            <p>
                We deem it to be one of our legitimate interests to communicate with our customers. Therefore,
                we may send you information about our products, services and future conferences if we consider
                you as a part of our customer base. This will be the case if you visited one of our previous
                conferences, or if you contacted us by mail or subscribed to our newsletter, or if you’re a
                customer of one of our business relationships or sponsors of (one of) our conferences.
            </p>
            <p>
                If you no longer want to receive our communications, you can unsubscribe by clicking the
                unsubscribe link contained at the bottom of any marketing email we send to you and following the
                instructions which appear in your browser following your clicking on that link.
            </p>
            <h2>4. Who has access to your personal data? </h2>
            <p>
                Only a limited number of people, companies, organisations or other entities have access to the
                personal data we’ve collected and process.
            </p>
            <p>
                Our cloud servers are hosted by DigitalOcean. More information about the hosting of cloud
                servers by DigitalOcean can be found <ExternalLink
                link="https://www.digitalocean.com/legal">here</ExternalLink>.
            </p>
            <p>
                Our ticket handling is done by FlexBillet (owned by Flexminds ApS). For more information about their services please see&nbsp;
                <ExternalLink link='https://translate.google.com/translate?sl=auto&tl=en&js=y&prev=_t&hl=en&ie=UTF-8&u=https%3A%2F%2Fflexminds.dk%2Fterms_da%2F'>flexminds.dk</ExternalLink> (Translated from Danish using Google Translate).</p>
            <p>
                Our board of GR8 ApS have access to the personal data we process.
            </p>
            <p>
                GR8Conf does not share your personal data with third parties for any other purpose than mentioned
                in this Privacy Declaration, unless you’ve given us your explicit consent to do so or when we
                are required to do so by law or for the purposes of prevention of fraud or other crime.
            </p>
            <p>
                We do however pass your information to our third party service providers, agents, subcontractors
                and other associated organisations for the purposes of completing tasks and providing services
                to you on our behalf (e.g. to process payments and back-up storage). GR8Conf only discloses the
                personal data to its sub-processors that are necessary for the execution of their services. and
                GR8Conf enters into a written agreement with each processor in which obligations are imposed to
                keep your information secure and to prevent your data is used for other purposes (for example
                the processor’s direct marketing purposes).
            </p>
            <p>
                If we transfer personal data to countries outside the EEA, we will, in all circumstances, take
                appropriate safeguards and establish legal grounds justifying such transfer.
            </p>
            <h2>5. How long do we keep your personal data?</h2>
            <p>
                We keep your personal data as long as required to comply with our legal obligations and to
                provide our services in a correct manner or because we have a legitimate interest to keep your
                personal data (longer).
            </p>
            <p>
                At any moment you have the possibility to object against a further processing of your personal
                data.
            </p>
            <h2>6. How do we protect your personal data?</h2>
            <p>
                GR8Conf takes appropriate measures to prevent misuse, loss, unauthorized access, unwanted
                disclosure and unauthorized modification. If you have the impression that your data is not
                properly secured or there are indications of abuse, please contact our customer service or
                via <MailTo email="info@gr8conf.org">info@gr8conf.org</MailTo>.</p>
            <h2>7. What rights do you have regarding the processing of your personal data?</h2>
            <p>
                The Regulation provides you with various rights regarding the processing of your personal data.
                If you wish to invoke your privacy rights, please contact <MailTo
                email="info@gr8conf.org">info@gr8conf.org</MailTo>. We will normally respond to your
                request within 30 days. This period can be extended by a further two months if the request is
                complex or if you send us several requests. We will notify you of this extension within 30 days
                after we’ve received your request.
            </p>
            <ol>
                <li>
                    <strong>Right of access</strong>: You have the right to know which of your personal data we’ve
                    collected and
                    process.
                </li>
                <li>
                    <strong>Right to rectification</strong>: It can happen that certain information held on you by
                    GR8Conf is not
                    (or has ceased to be) correct. You can ask for the data to be corrected or completed at any
                    time.
                </li>
                <li>
                    <strong>Right to erasure</strong>: You can ask to erase the personal data that we’ve collected and
                    process
                    about you (‘right to be forgotten’). We may deny this request if further processing of this
                    personal data is needed to comply with a legal obligation, for the executing of a contract
                    or to protect our legitimate interests or the legitimate interests of our clients, suppliers
                    or those of a third party.
                </li>
                <li><strong>Right to data portability</strong>: You are entitled to ask for personal data that you have
                    provided
                    GR8Conf with yourself be transferred back to you or to a third party. The data protection
                    laws do lay down a number of restrictions on exercise of this right, so that it is not
                    applicable to all data.
                </li>
            </ol>
            <p>
                Under the circumstances en conditions set by the Regulation, you also have the following rights
                regarding the processing of your personal data.
            </p>
            <ol>
                <li>
                    <strong>Right to restriction of processing</strong>:
                    The right to restrict the processing of your personal
                    data.
                </li>
                <li>
                    <strong>Right to object</strong>: If you disagree with how GR8Conf invokes its legitimate interests
                    to
                    process certain data, you can object. We shall heed objections unless there are overriding
                    grounds not to do so, such as when we process data with a view to combating fraud.
                </li>
            </ol>
            <p>
                If processing of your personal data is based upon your explicit and prior consent, you can revoke
                your consent at any time.
            </p>
            <p>
                In principle you can exercise these privacy rights free of charge. We ask to be as specific as
                possible when directing your request. In addition, we ask you to include a copy of the front and
                back of your identity card with every request.
            </p>
            <h2>8. Update Privacy Declaration</h2>
            <p>
                GR8Conf is entitled to update this Privacy Declaration by posting a new version on the website.
                As such, it is strongly recommended to regularly consult the website and the page explaining the
                Privacy Declaration, to make sure that you are aware of any changes.
            </p>
            <h2>9. Questions, requests or complaints</h2>
            <p>
                If you have any questions regarding this Privacy Declaration or GR8Conf’s privacy practices, or
                if you want to submit a request you can contact us using the following contact details:
            </p>
            <p>
                GR8 ApS<br/>
                Carl Nielsens Kvarter 23, st., DK-5000 Odense C<br/>
                T: +45 40 44 91 88<br/>
                Email address: <MailTo email="info@gr8conf.org">info@gr8conf.org</MailTo>
            </p>
        </PagePanel>
    </Page>);
export const CookieConsentPage = () => (
    <Page>
        <PagePanel title="Cookies">
            <p className="lead">To make this site work properly, we sometimes place small data files called cookies on
                your device. Most
                big websites do this too.
            </p>
            <h2>What are cookies?</h2>
            <p>A cookie is a small text file that a website saves on your computer or mobile device when you visit the
                site. It enables the website to remember your actions and preferences (such as login, language, font
                size and other display preferences) over a period of time, so you don’t have to keep re-entering them
                whenever you come back to the site or browse from one page to another.
            </p>
            <h2>How do we use cookies?</h2>
            <ul className="ul1">
                <li>
                    Remember information about you, so you don’t have to give it to us again. And again. And again
                </li>
                <li>
                    Keep you signed in
                </li>
                <li>
                    Help us understand how people are using our services, so we can make them better. We do this with
                    third party tools from Google Analytics.
                </li>
            </ul>
            <h2>How to control cookies</h2>
            <p>You can <b>control and/or delete</b> cookies as you wish – for details, see <ExternalLink
                link="http://www.aboutcookies.org/">
                aboutcookies.org</ExternalLink>. You can delete all cookies that are already on your computer and you
                can set most browsers to prevent them from being placed. If you do this, however, you may have to
                manually adjust some preferences every time you visit a site and some services and functionalities may
                not work.
            </p>
            <p>
                You can <b>reject</b> the cookies by turning them off in your browser.
            </p>
        </PagePanel>
    </Page>
);
