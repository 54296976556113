import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Questionnaire, StarRatingQuestion, TextAreaQuestion} from '../Questionnaire';
import FA from 'react-fontawesome';
import './Talks.css'
import {storeRating} from "../../data/actions/ratings";
import {connect} from "react-redux";
import {findSpeakers} from "../../data/helpers/talks";
import {showRatings} from "../../config";


class RatingModal extends Component {

    static propTypes = {
        speaker: PropTypes.object.isRequired,
        talk: PropTypes.object.isRequired,
        data: PropTypes.object,
        onSaveModal: PropTypes.func.isRequired,
        onCancelModal: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            data: props.data || {}
        }
    }

    qualityLevels = ['Poor', 'Needs more work.', 'This was ok. Average I would say.', 'This was pretty good.', 'One word: Cool!'];
    relevanceLevels = ['Irrelevant to me', 'Not really that relevant, but nice to know.', 'It sparked my interest...', 'I need to pursuade my boss to use it', 'Just what I was looking for!'];
    talkLevels = ['This was just an overview, right?', 'A bit more depth would have been nice', 'About right', 'I could follow it - unless I blinked', 'Way over my head...'];
    speakerLevels = ['Unprepared', 'A little more preparation would help', 'This was ok. Average I would say.', 'Good job indeed', 'Skills - she/he got skills!'];

    questionnaireChange = (data) => {
        this.setState({data: data});
    };

    onSave = () => {
        this.props.onSaveModal(this.state.data);
    };

    onCancel = () => {
        this.props.onCancelModal()
    };

    render() {
        const {speaker, talk} = this.props;
        const {data} = this.state;
        return <span>
            {speaker &&
            <Modal show={true} animation={false} onHide={this.onCancel} >
                <Modal.Header closeButton>
                    <img src={speaker.image} alt={speaker.name} className='rating-image img-thumbnail pull-right'/>
                    <h4>{talk.title}</h4>
                    <h5>Presented by {speaker.name}</h5>
                    <h6>{speaker.company}</h6>
                </Modal.Header>
                <Modal.Body>
                    <h4>Rate the presentation:</h4>
                    <Questionnaire onDataChanged={this.questionnaireChange} data={data}>
                        <StarRatingQuestion id='talkQuality'
                                            label='Quality'
                                            min={0}
                                            max={5}
                                            hints={this.qualityLevels}
                                            tooltip='Overall quality of slides, material, demos etc?'
                        />
                        <StarRatingQuestion id='talkRelevance'
                                            label='Relevance'
                                            min={0}
                                            max={5}
                                            hints={this.relevanceLevels}
                                            tooltip='How relevant was this content to you?'
                        />
                        <StarRatingQuestion id='talkLevel'
                                            label='Level'
                                            min={0}
                                            max={5}
                                            hints={this.talkLevels}
                                            tooltip='Did *you* get it? How was this sessions level'
                        />
                        <TextAreaQuestion id='talkComment' label='Comments' tooltip='Be verbose. What did you like? What did you not like?'/>
                        <h4 className='pad-top-30'>Rate the speaker:</h4>

                        <StarRatingQuestion id='speakerQuality'
                                            label='Quality'
                                            min={0}
                                            max={5}
                                            hints={this.speakerLevels}
                                            tooltip='How do you rate the speakers performance?'
                        />
                        <TextAreaQuestion id='speakerComment' label='Comments' tooltip='Give kudos, or help the speaker improve his presentation-fu'/>

                    </Questionnaire>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-info" onClick={this.onSave}>Save</Button>
                    <Button onClick={this.onCancel}>Cancel</Button>
                </Modal.Footer>
            </Modal>}
        </span>
    }
}

class Rating extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        speakersById: PropTypes.object,
        talks: PropTypes.array,
        ratings: PropTypes.array,
        isLoggedIn: PropTypes.bool,
        user: PropTypes.object,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.array,
        storeRating: PropTypes.func
    };
    static defaultProps = {
        talks: [],
        speakersById: {},
        ratings: []
    };

    constructor(props) {
        super(props);
        this.state = this.extractState(props)
    }


    extractState(props) {
        const {talks, speakersById, id, ratings, isLoggedIn} = props;
        const talk = (talks || []).find((element) => (element.id === id)) || {};
        const speakers = findSpeakers(talk, speakersById);
        const rating = ratings.find((element) => (element.id === id)) || {};
        const wasRated = rating.talkLevel || rating.talkQuality || rating.talkRelevance || rating.talkComment || rating.speakerQuality || rating.speakerComment;
        return {
            isLoggedIn: isLoggedIn,
            rated: wasRated,
            data: rating,
            showModal: false,
            talk: talk,
            speaker: speakers ? speakers[0] : {},
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(this.extractState(nextProps))
    }

    openModal = () => {
        this.setState({showModal: true})
    };

    closeModal = () => {
        this.setState({showModal: false})
    };

    onSave = (data) => {
        this.props.storeRating(this.props.id, data);
        this.closeModal()
    };

    render() {
        const {data, showModal, rated, speaker, talk, isLoggedIn} = this.state;
        const style = {color:  isLoggedIn ? (rated ? 'red' : 'white') : '#cccccc'};
        if(!showRatings) {
            return <span/>;
        }
        const tooltip = (<Tooltip id={data.id}>{isLoggedIn ? 'Click to rate':'Login to rate' }</Tooltip>);
        return <span>
            {showModal && <RatingModal speaker={speaker} talk={talk} onSaveModal={this.onSave} onCancelModal={this.closeModal} data={data}/>}
            <OverlayTrigger overlay={tooltip} placement="top">

            <span className='fa-stack gr8-stack' onClick={isLoggedIn ? this.openModal : undefined}>
                <FA name='heart' stack='1x' style={style}/>
                <FA name='heart-o' stack='1x'/>
                </span>
            </OverlayTrigger>
        </span>
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.oauth.isLoggedIn,
        user: state.oauth.user,
        ratings: state.ratings,
        talks: state.talks,
        speakersById: state.speakersById,
        isLoading: state.ratingsIsLoading || state.speakersIsLoading || state.talksIsLoading,
        isSaving: state.ratingsIsSaving
    };
};
const mapDispatchToProps = {
    storeRating
};

export default connect(mapStateToProps, mapDispatchToProps)(Rating);
