import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from 'redux-thunk';

import {speakers, speakersById, speakersIsLoading, speakersHasErred, featuredSpeakers} from "./reducers/speakers";
import {speakersFetchData} from "./actions/speakers";
import {talks, talksIsLoading, talksHasErred} from "./reducers/talks";
import {talksFetchData} from "./actions/talks";
import {tags, tagsIsLoading, tagsHasErred} from "./reducers/tags";
import {tagsFetchData} from "./actions/tags";
import {agenda, agendaIsLoading, agendaHasErred} from "./reducers/agenda";
import {agendaFetchData} from "./actions/agenda";
import {prices, pricesIsLoading, pricesHasErred} from "./reducers/prices";
import {pricesFetchData} from "./actions/prices";
import {sponsors, sponsorsHasErred} from "./reducers/sponsors";
import {sponsorsFetchData} from "./actions/sponsors";
import {oauth} from "./reducers/user";
import {fetchOauthCode} from "./actions/user";
import {favorites, favoritesIsLoading, favoritesIsSaving} from "./reducers/favorites";
import {fetchFavorites} from "./actions/favorites";
import {conferenceRating, ratings, ratingsIsLoading, ratingsIsSaving, leaderboard} from "./reducers/ratings";
import {fetchConferenceLeaderboard, fetchConferenceRatings, fetchRatings} from "./actions/ratings";
import {agendaApp} from "../config";

const reducers = combineReducers({
    speakers, speakersById, featuredSpeakers, speakersIsLoading, speakersHasErred,
    talks, talksIsLoading, talksHasErred,
    tags, tagsIsLoading, tagsHasErred,
    agenda, agendaIsLoading, agendaHasErred,
    prices, pricesIsLoading, pricesHasErred,
    sponsors, sponsorsHasErred,
    oauth,
    favorites, favoritesIsLoading, favoritesIsSaving,
    ratings, conferenceRating, ratingsIsLoading, ratingsIsSaving, leaderboard,
});

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

const initialState = {
    speakers: [],
    speakersById: {},
    featuredSpeakers: [],
    speakersIsLoading: false,
    speakersHasErred: false,
    talks: [],
    talksIsLoading: false,
    talksHasErred: false,
    tags: {
        all: [],
        active: []
    },
    tagsIsLoading: false,
    tagsHasErred: false,
    agenda: [],
    agendaIsLoading: false,
    agendaHasErred: false,
    prices: [],
    pricesIsLoading: false,
    pricesHasErred: false,
    sponsors: [],
    sponsorsHasErred: false,
    oauth: {
        fetchingCode: false,
        checkingCode: false,
        code: user.status === 200 ? '' : undefined,
        isLoggedIn: user.status === 200,
        needTwitterInfo: false,
        user: user
    },

    favoritesIsLoading: false,
    favoritesIsSaving: [],
    favorites: [],
    ratingsIsLoading: false,
    ratingsIsSaving: [],
    ratings: [],
    conferenceRating: {},
    leaderboard: []
};
const store = createStore(reducers, initialState, applyMiddleware(thunk));
store.dispatch(pricesFetchData());
store.dispatch(sponsorsFetchData());
store.dispatch(speakersFetchData());
store.dispatch(talksFetchData());
store.dispatch(tagsFetchData());
store.dispatch(agendaFetchData());
store.dispatch(fetchOauthCode());
store.dispatch(fetchFavorites());
store.dispatch(fetchRatings());
store.dispatch(fetchConferenceRatings());
if (agendaApp) {
    store.dispatch(fetchConferenceLeaderboard());
}
export default store;
