import React, {Component} from 'react';
import {Page, PagePanel} from "../../components/helpers/PagePanel";
import {Questionnaire, StarRatingQuestion, TextAreaQuestion} from "../../components/Questionnaire";
import {storeConferenceRating} from "../../data/actions/ratings";
import {connect} from "react-redux";

class ConfRatingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        const data = nextProps.data;
        this.setState({data: data});
    }

    questionnaireChange = (data) => {
        this.props.storeConferenceRating(data);
    };

    render() {
        const normalLevels = ['Bad', 'Below Average', 'Average', 'Above Average', 'Excellent'];
        const returnLevels = ['No', 'Perhaps if the prices is better', 'I hope', 'Yes!', 'I would not miss it!'];
        const {isLoggedIn} = this.props;
        const {data} = this.state;
        return <Page>
            <PagePanel title="Rate GR8Conf & JDK.io">
                <h2>Rate us!</h2>

                <p>
                    As conference organizers it is key to know how you liked the conference. That is why we ask you to spend a minute to rate our conference.</p>

                <p>Equally the speakers likes feedback for their talks. We will share
                    the anonymized result with the speakers.</p>
                <h2>and win!</h2>
                <p>When you rate the conference you get 10 points, and for each additional talk you rate you get one point.</p>
                <p>The person with the most points when the conference ends wins! The winner will get a VIP conference passes, the runner up one VIP conference pass, and 2nd runner up one full conference pass.</p>
                <p>A VIP conference pass is access to all three days plus an invitation to speakers dinner the night before the conference.</p>
                <p>Winners will be picked at the last session of the conference.</p>
                <p className="text-muted">Winners needs to be present to win.
                    Speakers and crew are not eligible to win.</p>
                {!isLoggedIn && <div>You need to be logged in to place your ratings</div>}
                {isLoggedIn &&
                <Questionnaire onDataChanged={this.questionnaireChange} data={data}>
                    <h3>Conference Overall</h3>
                    <p>Tell us your overall impression of the conference</p>
                    <StarRatingQuestion id='overall'
                                        label='Overall impression'
                                        min={0}
                                        max={5}
                                        hints={normalLevels}
                                        tooltip='Rank us!'
                    />
                    <TextAreaQuestion id="overallComments"
                                      label="General Comments"
                                      tooltip='Be verbose! Unless you tell us, we cannot fix it!'/>
                    <h3>Pre conference</h3>
                    <p>How was your experience before the conference?</p>
                    <StarRatingQuestion id='preInfo'
                                        label='Information'
                                        min={0}
                                        max={5}
                                        hints={normalLevels}
                                        tooltip='How was the information level prior to the conference?'
                    />
                    <StarRatingQuestion id='preWebsite'
                                        label='Website'
                                        min={0}
                                        max={5}
                                        hints={normalLevels}
                                        tooltip='Did you find what you needed on our website?'
                    />
                    <StarRatingQuestion id='preRegistration'
                                        label='Registration'
                                        min={0}
                                        max={5}
                                        hints={normalLevels}
                                        tooltip='Did our registration process work for you?'
                    />
                    <TextAreaQuestion id="preComments"
                                      label="Pre-conference comments"
                                      tooltip='What can we do to improve?'/>
                    <h3>Venue and catering</h3>
                    <p>How was your experience during the conference?</p>
                    <StarRatingQuestion id='venueImpression'
                                        label='Impression'
                                        min={0}
                                        max={5}
                                        hints={normalLevels}
                                        tooltip='Did you like the venue? Location, rooms etc?'
                    />
                    <StarRatingQuestion id='venueCatering'
                                        label='Catering'
                                        min={0}
                                        max={5}
                                        hints={normalLevels}
                                        tooltip='How was the food?'
                    />
                    <StarRatingQuestion id='venueWorkshopRooms'
                                        label='Workshop rooms'
                                        min={0}
                                        max={5}
                                        hints={normalLevels}
                                        tooltip='If you attended the workshops, how was the rooms?'
                    />
                    <StarRatingQuestion id='venueAuditoriums'
                                        label='Auditoriums'
                                        min={0}
                                        max={5}
                                        hints={normalLevels}
                                        tooltip='How was the auditoriums'
                    />
                    <TextAreaQuestion id="venueComments"
                                      label="Venue and catering comments"
                                      tooltip='Ideas and tips for improvements?'/>
                    <h3>Conference content</h3>
                    <p>Did the content of the conference meet your overall expectations?</p>
                    <StarRatingQuestion id='confTopicRelevance'
                                        label='Overall topic relevance'
                                        min={0}
                                        max={5}
                                        hints={normalLevels}
                                        tooltip='Did we meet your expectations?'
                    />
                    <StarRatingQuestion id='confPresentations'
                                        label='Overall presentation quality'
                                        min={0}
                                        max={5}
                                        hints={normalLevels}
                                        tooltip='How was the level?'
                    />
                    <TextAreaQuestion id="confComments"
                                      label="Conference comments"
                                      tooltip='Share your thoughts'/>
                    <h3>Returning next year?</h3>
                    <p>How likely is it, that you will come to GR8Conf & JDK.io 2023?</p>
                    <StarRatingQuestion id='beBackSelf'
                                        label='If you can choose'
                                        min={0}
                                        max={5}
                                        hints={returnLevels}
                                        tooltip='When you feel what you feel deep inside.'
                    />
                    <StarRatingQuestion id='beBackBoss'
                                        label='If your boss decides'
                                        min={0}
                                        max={5}
                                        hints={returnLevels}
                                        tooltip='What would your boss say?'
                    />
                </Questionnaire>}

            </PagePanel>
        </Page>
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.oauth.isLoggedIn,
        user: state.oauth.user,
        data: state.conferenceRating,
        isLoading: state.ratingsIsLoading
    };
};
const mapDispatchToProps = {
    storeConferenceRating
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfRatingPage);
