import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";

export class AgendaDates extends Component {
    static propTypes = {
        dates: PropTypes.array.isRequired,
        date: PropTypes.object.isRequired,
        onDateChange: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.state = {
            date: this.dateKey(props.date),
            dates: props.dates,
            onDateChange: props.onDateChange
        };
    }

    dateKey = (date) => (date ? date.format('YYYY-MM-DD') : undefined);

    handleChange(date) {
        this.setState({date: date});
        if (this.state.onDateChange) {
            this.state.onDateChange(moment(date, 'YYYY-MM-DD'))
        }
    }

    render() {
        return <ToggleButtonGroup justified
                                  type="radio"
                                  name="date"
                                  value={this.state.date}
                                  onChange={this.handleChange}
        >
            {this.props.dates.map((date, index) => (
                <ToggleButton key={index} value={date.format('YYYY-MM-DD')}>{date.format('ddd MMMM Do')}</ToggleButton>
            ))}
        </ToggleButtonGroup>;
    }
}