import React from 'react';
import {Link} from "react-router-dom";
import Iframe from 'react-iframe';
import {Page, PagePanel} from "../../components/helpers/PagePanel";
import {ExternalLink, MailTo} from "../../components/helpers/EasyLinks";
import FA from 'react-fontawesome';

export const InformationPage = () => (
    <Page>
        <PagePanel className="information" title="Information">
            <p className="lead">On these pages we will try to provide you with all the information you need to attend
                GR8Conf & JDK.io 2022.</p>

            <div className="questions-and-answers">
                <h3>Q: Where is the conference held?</h3>
                <h4>A: The conference will take place at CabInn Copenhagen</h4>
                <p>For more information, read <Link to="/venue">here</Link></p>

                <h3>Q: What is the conference language</h3>
                <h4>A: All talks and information is in English.</h4>
                <p>In Denmark almost everyone speaks a proficient English.</p>

                <h3>Q: Do you offer group discounts</h3>
                <h4>For companies sending a group of 5 or more people we offer a discount</h4>
                <p>Please contact <MailTo email="info@gr8conf.org"/> for more information.</p>

                <h3>Q: What hotels do you recommend?</h3>
                <h4>A: We have a list of hotels near by</h4>
                <p>We have a discount agreement with the Arp Hansen hotel chain in the Copenhagen Metro areal. You can
                    get up to 15% discount.
                    Checkout
                    the <Link to="/hotels">discounts and maps</Link> page.</p>

                <h3>Q: How do I get to Copenhagen?</h3>
                <h4>A: You should arrive at Copenhagen Airport.</h4>
                <p>The venue and hotels are within reach of Copenhagen Airport by public transportation and/or taxi. We
                    have collected
                    <Link to="/getting-around"> information</Link> about getting to Copenhagen.</p>

                <h3>Q: How do I get around in Copenhagen?</h3>
                <h4>A: There is a fine meshed public transportation system in Copenhagen.</h4>
                <p>You can find more information on our <Link to="/getting-around">getting around</Link> page.</p>

                <h2>Other questions?</h2>
                <p>If you have further questions, please write to <MailTo email="info@gr8conf.org"/> and we will answer
                    as quickly as possible. If the question and answer has general interest, it will also be added to this
                    page.</p>
            </div>
        </PagePanel>
    </Page>
);

export const VenuePage = () => (
    <Page>
        <PagePanel className="venue" title="Venue">
            <p className="lead">The conference takes place at CabInn Copenhagen</p>
            <p><FA name="information"/> Note that this is not the same venue as previous years! </p>
            <p>Cabinn Copenhagen<br/>
                Arni Magnussons Gade 1<br/>
                1577 København<br/>
                Denmark</p>
            <div className="iframe-loading">
                <Iframe
                    url="https://www.google.com/maps/d/u/1/embed?mid=1HRfZXUU6_78ap7ATNjZna9t3nbxvfW8&ehbc=2E312F"
                    width={1000} height={600}
                    position="relative"/>
            </div>
        </PagePanel>
    </Page>
);

const HotelsAndMetro = () => (
    <div>
        <h2>Map of hotels and metro stations</h2>
        <div className="iframe-loading">

            <Iframe url="https://www.google.com/maps/d/u/1/embed?mid=1HRfZXUU6_78ap7ATNjZna9t3nbxvfW8&ehbc=2E312F"
                    width={1000} height={600}
                    position="relative"/>
        </div>
    </div>
);

export const HotelsPage = () => (
    <Page>
        <PagePanel className="hotels" title="Hotels">
            <p className="lead">GR8Conf has a discount agreement with the following hotels. The links will take you to
                the booking page with the
                discount:</p>
            <h2>15% discount:</h2>
            <ul>
                <li><ExternalLink link="http://bit.ly/gr8conf-wakeup">Wakeup Copenhagen, Borgergade</ExternalLink>
                    <small>(The Speakers Hotel)</small>
                </li>
                <li><ExternalLink link="http://bit.ly/gr8conf-wakeup">Wakeup Copenhagen, Carsten Niebuhrs
                    Gade</ExternalLink></li>
                <li><ExternalLink link="http://bit.ly/gr8conf-wakeup">Wakeup Copenhagen, Bernstorffsgade</ExternalLink></li>
            </ul>
            <h3>10% discount:</h3>
            <ul>
                <li><ExternalLink link="http://bit.ly/gr8conf-phoenix">Hotel Phoenix Copenhagen</ExternalLink></li>
                <li><ExternalLink link="http://bit.ly/gr8conf-cph-strand">Copenhagen Strand</ExternalLink></li>
                <li><ExternalLink link="http://bit.ly/gr8conf-cph-island">Copenhagen Island</ExternalLink></li>
                <li><ExternalLink link="http://bit.ly/gr8conf-71-nyhavn">71 Nyhavn Hotel</ExternalLink></li>
                <li><ExternalLink link="http://bit.ly/gr8conf-imperial">Hotel Imperial</ExternalLink></li>
                <li><ExternalLink link="http://bit.ly/gr8conf-square">The Square</ExternalLink></li>
                <li><ExternalLink link="http://bit.ly/gr8conf-tivoli-hotel">Tivoli Hotel</ExternalLink></li>
            </ul>
            <p><strong>Note: </strong> When booking with the links above, you can freely cancel the reservation up to
                the day before checking in.</p>
            <p>Expand the map below to see where the hotels are located. Hotels marked with blue has the discounts,
                others marked in dark grey. Light gray are low-cost alternatives like hostels and B&B.</p>
            <HotelsAndMetro/>
        </PagePanel>
    </Page>
);

export const GettingAroundPage = () => (
    <Page>
        <PagePanel title="Getting Around">
            <h2>Arrival</h2>
            <p>If you go to GR8Conf by air, your destination should be <ExternalLink link="https://www.cph.dk/en/">Copenhagen
                Airport
                (CPH)</ExternalLink>,
                and if you arrive by train, set your destination to <ExternalLink
                    link="https://www.dsb.dk/en/kundeservice/stationer/kobenhavn-h/">Copenhagen
                    Central Station</ExternalLink>.</p>
            <h2>Public transportation</h2>
            <p>Use trains or metro to get to the center of Copenhagen. <ExternalLink
                link="https://dinoffentligetransport.dk/service/for-tourists/">DOT
                Tourist Information</ExternalLink> can help you plan your trip around Copenhagen. This page also contain
                information about buying
                tickets
                for public transportation.</p>

            <h2>Payment and currencies</h2>
            <p>The currency in Denmark is Danish Kroner (DKK), but you can pay with credit cards (VISA, MasterCard) in
                all restaurants,
                hotels, taxi etc. </p>

            <HotelsAndMetro/>

        </PagePanel></Page>
);










