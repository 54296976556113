import React from 'react';
import {Col, FormLabel, FormControl, FormGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import FA from 'react-fontawesome';

export const FieldGroup = ({id, label, help, tooltip, ...props}) => {
    const tooltipComponent = (<Tooltip id={id + '_tooltip'} placement="top">{tooltip}</Tooltip>);

    const tooltipOverlay = (
        <OverlayTrigger placement="top" overlay={tooltipComponent}>
            <FA name="info-circle"/>
        </OverlayTrigger>
    );

    // noinspection JSAnnotator
    return (
        <FormGroup controlId={id}>
            <Col sm={4} className="text-left form-label">
                {label} {tooltip && tooltipOverlay}
            </Col>
            <Col sm={8}>
                {props.type && <FormControl id={id} {...props} />}
                {props.children && React.Children.map(props.children, (child: React.ReactElement) => {
                    return React.cloneElement(child, {...props})
                })}

                {help && <div>{help}</div>}
            </Col>
        </FormGroup>
    );
};
