import React, {Component} from 'react';
import {Link, Route, Router, Switch} from 'react-router-dom';
import history from './history';
import './App.css';
import Navigation from './navigation/Navigation';
import Footer from './footer/Footer';
import Home from './pages/home/Home';
import SpeakersPage from './pages/speakers/SpeakersPage';
import TalksPage from "./pages/talks/TalksPage";
import Registration from "./pages/registration/RegistrationPage";
import {GettingAroundPage, HotelsPage, InformationPage, VenuePage} from "./pages/information/InformationPage";
import {AboutPage} from "./pages/information/About";
import {showAgenda, showCFP, showRatings, showRegistration, showSpeakers, tawkIoToken} from './config';
import {CodeOfConduct, CookieConsentPage, PrivacyNoticePage} from "./pages/information/coc/CodeOfConduct";
import {TheCrewPage} from "./pages/information/crew/TheCrewPage";
import {ImpressionsPage} from "./pages/information/impressions/ImpressionsPage";
import ScrollToTop from "./components/helpers/ScrollToTop";
import Sponsors from "./pages/sponsors/SponsorsPage";
import AgendaPage from "./pages/agenda/AgendaPage";
import TawkIO from "./components/TawkIO";
import ConfRatingPage from "./pages/rating/ConfRatingPage";
import {CFPPage} from "./pages/information/cfp/CFP";
import CookieBanner from "react-cookie-banner";
import TheEnd from "./pages/theend/TheEnd";

class App extends Component {


    render() {
        return (
            <div>
                <Router history={history}>
                    <ScrollToTop>
                        <div className="App">
                            <Navigation/>
                            <div>
                                <Switch>
                                    <Route path="/" exact component={TheEnd}/>
                                    <Route path="/home" exact component={Home}/>
                                    {showRegistration &&
                                        <Route path="/registration/:voucher?" strict={false} component={Registration}/>}
                                    {showAgenda && <Route path="/agenda" component={AgendaPage}/>}
                                    {showSpeakers && <Route path="/speakers" component={SpeakersPage}/>}
                                    {showSpeakers && <Route path="/talks" component={TalksPage}/>}
                                    {showCFP && <Route path="/cfp" component={CFPPage}/>}
                                    <Route path="/information" component={InformationPage}/>
                                    <Route path="/venue" component={VenuePage}/>
                                    <Route path="/hotels" component={HotelsPage}/>
                                    <Route path="/getting-around" component={GettingAroundPage}/>
                                    <Route path="/impressions" component={ImpressionsPage}/>
                                    <Route path="/code-of-conduct" component={CodeOfConduct}/>
                                    <Route path="/privacy" component={PrivacyNoticePage}/>
                                    <Route path="/cookies" component={CookieConsentPage}/>
                                    <Route path="/about" component={AboutPage}/>
                                    <Route path="/crew" component={TheCrewPage}/>
                                    <Route path="/sponsors" component={Sponsors}/>
                                    {showRatings && <Route path="/rating" component={ConfRatingPage}/>}
                                    <Route path='*' exact={true} component={Home}/>
                                </Switch>
                                <CookieBanner
                                    message="Cookie policy: Yes, we use them to keep the site working, but no tracking cookies are set. Nothing personal though!&nbsp;"
                                    link={(<span><Link to="/cookies">Read more</Link> </span>)}
                                    onAccept={() => {
                                    }}
                                    cookie="user-has-accepted-cookies-2020"
                                    dismissOnScroll={false}
                                    disableStyle={true}
                                />
                            </div>
                        </div>
                    </ScrollToTop>
                    <Footer/>
                </Router>
            </div>
        );
    }
}

export default App;
