import React, {Component} from 'react';
import SpeakersList from '../../components/speakers/SpeakersList';
import {connect} from "react-redux";
import {Page, PagePanel} from "../../components/helpers/PagePanel";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import history from '../../history';
import SpeakerInfo from "../../components/speakers/SpeakerInfo";
import FA from 'react-fontawesome'
import background from './speakers.jpg'
import {Disclaimer} from "../../components/disclaimer/Disclaimer";

class Speaker extends Component {
    render() {
        const {name, speakers} = this.props;
        const uid = Number.parseInt(this.props.uid || 0, 0);
        if (speakers && speakers.length > 0 && (name || uid)) {
            const speaker = speakers.find(speaker => {
                return speaker.id === uid || speaker.twitter === name
            });
            if (speaker) {
                if (speaker.id === uid) {
                    // Use twitter name instead of id
                    history.replace('/speakers/' + speaker.twitter);
                }
                return <PagePanel title={"Speaker: " + speaker.name}>
                    <div>
                        <Link to="/speakers"><FA name="arrow-circle-left"/> back to list of speakers</Link>
                    </div>
                    <SpeakerInfo speaker={speaker} detailed={true}/>
                </PagePanel>
            }
            return <Redirect to="/speakers"/>
        }
        return <div/>
    }
}


class SpeakersPage extends Component {
    render() {

        const speakers = this.props.speakers;
        return <Page className="speakers">
            <Switch>
                <Route exact path="/speakers" render={() => (
                    <div>
                        <PagePanel bgImage={background} className='no-padding text-center' style={{minHeight: 300}}>
                            <div className="top">
                                <h1>Speakers at GR8Conf & JDK.io</h1>
                                <p className="lead">
                                    Speakers are the backbone of any conference. GR8Conf & JDK.io is no different.
                                </p>
                                <p className="lead">
                                    We aim at finding the best speakers with the deepest knowledge of the subject matter.
                                </p>
                            </div>

                        </PagePanel>
                        <PagePanel>
                            <SpeakersList speakers={speakers}/>
                            {this.props.hasErred && <p>Sorry! There was an error loading the items</p>}
                            {this.props.isLoading && <h3 style={{paddingLeft: 20}}>Stand by while loading awesome speakers....</h3>}
                            <Disclaimer/>
                        </PagePanel>
                    </div>
                )}/>
                }/>
                <Route path="/speakers/byid/:uid" render={(props) => (
                    <Speaker speakers={speakers} uid={props.match.params.uid}/>
                )}/>
                <Route path="/speakers/:name" render={(props) => (
                    <Speaker speakers={speakers} name={props.match.params.name}/>
                )}/>
            </Switch>
        </Page>;

    }
}

const mapStateToProps = state => {
    return {speakers: state.speakers, isLoading: state.speakersIsLoading};
};


export default connect(mapStateToProps)(SpeakersPage);
