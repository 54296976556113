import {SPEAKERS_HAS_ERRED, SPEAKERS_IS_LOADING, SPEAKERS_FETCHED_OK, FEATURED_SPEAKERS_FETCHED_OK, SPEAKERS_GET_SPEAKER} from '../constants/speakers'
import {speakersUrl} from "../../config";

export const speakersHasErred = (bool) => ({
    type: SPEAKERS_HAS_ERRED,
    hasErred: bool
});

export const speakersIsLoading = (bool) => ({
    type: SPEAKERS_IS_LOADING,
    isLoading: bool
});

export const speakersFetchOk = (speakers) => ({
    type: SPEAKERS_FETCHED_OK,
    speakers
});

export const featuredSpeakersFetchedOk = (featuredSpeakers) => ({
    type: FEATURED_SPEAKERS_FETCHED_OK,
    featuredSpeakers
});

export const getSpeaker = (id) => ({
    type: SPEAKERS_GET_SPEAKER,
    id
});

export function speakersFetchData() {
    return (dispatch) => {
        dispatch(speakersIsLoading(true));

        window.setTimeout(() => (fetch(speakersUrl)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                dispatch(speakersIsLoading(false));

                return response;
            })
            .then((response) => response.json())
            .then((speakers) => {
                dispatch(speakersFetchOk(speakers));
                return speakers;
            })
            .then((speakers) => dispatch(featuredSpeakersFetchedOk(speakers.filter(speaker => speaker.featured))))
            .catch(() => dispatch(speakersHasErred(true)))), 200);
    };
}