import React from 'react';
import {Background, Parallax} from 'react-parallax';
import CountdownTimer from 'react-awesome-countdowntimer';
import moment from 'moment';
import '../Home.css'
import BackgroundSlideshow from '../../../components/slideshow/BackgroundSlideshow';
import image1 from '../../../assets/images/1.jpg';
// import image2 from '../../../assets/images/2.jpg';
import image3 from '../../../assets/images/3.jpg';
import image4 from '../../../assets/images/4.jpg';
import image5 from '../../../assets/images/5.jpg';
import image6 from '../../../assets/images/6.jpg';
import logo from '../../../assets/images/logo.svg'
import groovy from '../../../assets/images/groovy.svg'
import grails from '../../../assets/images/grails.svg'
import geb from '../../../assets/images/geb.svg'
import spock from '../../../assets/images/spock.svg'
import java from '../../../assets/images/java.svg'
import quarkus from '../../../assets/images/quarkus.svg'
import helidon from '../../../assets/images/helidon.svg'
import micronaut from '../../../assets/images/micronaut.svg'
import gradle from '../../../assets/images/gradle.svg'
import jenkins from '../../../assets/images/jenkins.svg'
import boot from '../../../assets/images/spring-boot.svg'
import {Col, Container, Row} from "react-bootstrap";

const Event = () => (<div className="event">
    <Parallax
        strength={-240}
        blur={{min: -10, max: 20}}
    >
        <Background>
            <div style={{position: 'relative', width: '100%', height: 1050}}>
                <BackgroundSlideshow style={{height: '1050', top: 95}} imageDuration={10}
                                     images={[image1, image3, image4, image5, image6]}/>
            </div>

        </Background>
        <div className="conference">
            <div className="conference-header">
                <Container>

                    <Row>
                        <Col lg={3} className="tech-logos d-none d-lg-block">
                            <div>
                                <img src={groovy} alt="Groovy" title="Groovy"/>
                            </div>
                            <div>
                                <img src={grails} alt="Grails" title="Grails"/>
                            </div>
                            <div>
                                <img src={spock} alt="Spock Framework" title="Spock Framework"/>
                            </div>
                            <div>
                                <img src={geb} alt="GEB" title="GEB"/>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Row>
                                <Col lg={4} className="tech-logos  d-none d-lg-block">
                                    <div>
                                        <img src={micronaut} alt="Micronaut" title="Micronaut"/>
                                    </div>

                                </Col>
                                <Col lg={4} className="tech-logos  d-none d-lg-block">
                                    <div>
                                        <img src={quarkus} alt="Quarkus" title="Quarkus"/>
                                    </div>

                                </Col>
                                <Col lg={4} className="tech-logos  d-none d-lg-block">
                                    <div>
                                        <img src={helidon} alt="Helidon" title="Helidon"/>
                                    </div>

                                </Col>
                            </Row>
                            <div className="conference-info">
                                <div className="name">
                                    <img src={logo} style={{height: 300}} alt="GR8Conf & JDK.io 2022"/>
                                </div>
                                <div className="dates">
                                    November 16th & 17th 2022
                                </div>
                                <div className="where">
                                    <strong>Copenhagen</strong>, Denmark
                                </div>
                                <div className="tag-line">
                                    <p><strong>All Things Groovy and Java</strong>
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} className="tech-logos  d-none d-lg-block">
                            <div>
                                <img src={java} alt="Java" title="Java"/>
                            </div>
                            <div>
                                <img src={boot} alt="Spring Boot" title="Spring Boot"/>
                            </div>
                            <div>
                                <img src={gradle} alt="Gradle" title="Gradle"/>
                            </div>
                            <div>
                                <img src={jenkins} alt="Jenkins CI" title="Jenkins CI"/>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
            <div className="countdown">
                <CountdownTimer endDate={moment('16/11/2022 08:00:00', 'DD/MM/YYYY hh:mm:ss')}/>
            </div>
        </div>
    </Parallax>
</div>);

export default Event;
