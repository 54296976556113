import React, {Component} from 'react';
import TalksList from '../../components/talks/TalksList';
import {connect} from "react-redux";
import {Page, PagePanel} from "../../components/helpers/PagePanel";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import TalkInfo from "../../components/talks/TalkInfo";
import FA from 'react-fontawesome'
import background from './talks.jpg'
import TagFilter from "../../components/tags/TagFilter";
import {Disclaimer} from "../../components/disclaimer/Disclaimer";

class Talk extends Component {

    render() {
        const {talks} = this.props;
        const id = Number.parseInt(this.props.id, 0);
        if (talks && talks.length > 0 && id) {
            const talk = talks.find(talk => {
                return talk.id === id
            });
            if (talk) {
                return <PagePanel title={"Talk: " + talk.title}>
                    <div>
                        <Link to="/talks"><FA name="arrow-circle-left"/> back to list of talks</Link>
                    </div>
                    <TalkInfo talk={talk} detailed={true}/>
                </PagePanel>
            }
            return <Redirect to="/talks"/>
        }
        return <div/>
    }
}


class TalksPage extends Component {
    render() {

        const {talks, tags, isLoading, talksHasErred} = this.props;

        return <Page className="talks">
            <Switch>
                <Route exact path="/talks" render={() => (
                    <div>
                        <PagePanel bgImage={background} className='no-padding text-center' style={{minHeight: 300}}>
                            <div className="top">
                                <h1>Talks at GR8Conf & JDK.io</h1>
                                <p className="lead">
                                    This is what conferences are all about - the talks!
                                </p>
                                <p className="lead">
                                    At GR8Conf & JDK.io you can find everything from beginners content to deeply advanced subjects - all presented by the best
                                    speakers available!
                                </p>
                            </div>

                        </PagePanel>
                        <PagePanel>
                            <TagFilter placeholder="Filter by your favorite tag(s)"/>
                            <TalksList talks={talks} tags={tags}/>
                            {talksHasErred && <p>Sorry! There was an error loading the items</p>}
                            {isLoading && <h3 style={{paddingLeft: 20}}>Stand by while loading awesome talks....</h3>}
                            <Disclaimer/>

                        </PagePanel>
                    </div>
                )}/>
                }/>
                <Route path="/talks/:id" render={(props) => (
                    <Talk talks={talks} id={props.match.params.id}/>
                )}/>
            </Switch>
        </Page>;

    }
}

const mapStateToProps = state => {
    return {
        talks: state.talks,
        tags: state.tags,
        isLoading: state.talksIsLoading,
    };
};


export default connect(mapStateToProps)(TalksPage);
