import React, {Component} from 'react';
import {Container, Nav, Navbar, NavDropdown, NavItem} from "react-bootstrap";
import {IndexLinkContainer} from "react-router-bootstrap";
import {Link} from "react-router-dom";
import './Navbar.css'
import logo from '../assets/images/GR8Conf.svg'
import {showAgenda, showCFP, showLogin, showRatings, showRegistration, showSpeakers} from "../config";
import PropTypes from 'prop-types';
import User from "./User";

class NavTo extends Component {
    constructor(props) {
        super(props);
        this.onClickMenuItem = this.onClickMenuItem.bind(this)
    }

    onClickMenuItem(event) {
        if (this.props.onClick && typeof this.props.onClick === 'function') {
            this.props.onClick.call(event)
        }
    }

    render() {
        const {to, title, eventKey, dropdownItem, renderIf} = this.props;
        if (renderIf === undefined || renderIf) {
            if(dropdownItem) {
                return <IndexLinkContainer to={to}>
                    <NavDropdown.Item eventKey={eventKey} onClick={this.onClickMenuItem}>{title}</NavDropdown.Item>
                </IndexLinkContainer>

            } else {
                return <IndexLinkContainer to={to}>
                    <Nav.Link eventKey={eventKey} onClick={this.onClickMenuItem}>{title}</Nav.Link>
                </IndexLinkContainer>
            }
        } else {
            return null
        }
    };
}

NavTo.propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    eventKey: PropTypes.string,
    dropdownItem: PropTypes.bool,
    renderIf: PropTypes.bool
};

class NavigationDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };

        this.onToggle = this.onToggle.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onToggle() {
        this.setState({open: !this.state.open});
    }

    onClick() {
        this.setState({open: false});
        if (this.props.onClick && typeof this.props.onClick === 'function') {
            this.props.onClick.call(this)
        }
    }

    render() {
        // noinspection JSAnnotator
        return <NavDropdown title={this.props.title} id={this.props.id} open={this.state.open} onToggle={this.onToggle}>
            {React.Children.map(this.props.children, (child: React.ReactElement<NavTo>, index) =>
                React.cloneElement(child, {
                    eventKey: this.props.eventKey + '.' + index,
                    onClick: this.onClick,
                    dropdownItem: true,
                })
            )};
        </NavDropdown>
    }
}


class Navigation extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.state = {
            expanded: false
        }
    }

    onToggle() {
        this.setState({expanded: !this.state.expanded})
    }

    onClick() {
        this.setState({expanded: false})
    }

    render() {
        return <Navbar fixed="top" expand="lg" onToggle={this.onToggle} className="justify-content-between">
            <Container>
            <Navbar.Brand>
                <Link to="/">
                    <img src={logo} className="logo" alt="GR8Conf & JDK.io 2022"/>
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle/>
            <Navbar.Collapse className="justify-content-end">
                {false && <Nav>
                    <NavTo eventKey="1" to="/" title="Home" onClick={this.onClick}/>
                    <NavTo eventKey="2" to="/registration" renderIf={showRegistration} title="Registration" onClick={this.onClick}/>
                    <NavTo eventKey="3" to="/cfp" title="CFP" renderIf={showCFP} onClick={this.onClick}/>
                    <NavTo eventKey="3" to="/agenda" title="Agenda" renderIf={showAgenda} onClick={this.onClick}/>
                    <NavTo eventKey="4" to="/speakers" title="Speakers" renderIf={showSpeakers} onClick={this.onClick}/>
                    <NavTo eventKey="5" to="/talks" title="Talks" renderIf={showSpeakers} onClick={this.onClick}/>
                    <NavTo eventKey="6" to="/sponsors" title="Sponsors" onClick={this.onClick}/>
                    <NavTo eventKey="2" to="/rating" renderIf={showRatings} title="Rate us!" onClick={this.onClick}/>
                    <NavigationDropdown eventKey="7" title="Information" id="information" onClick={this.onClick}>
                        <NavTo to="/about" title="About"/>
                        <NavTo to="/information" title="FAQ"/>
                        <NavTo to="/venue" title="Venue" />
                        <NavTo to="/hotels" title="Hotels" />
                        <NavTo to="/getting-around" title="Getting around" />
                    </NavigationDropdown>
                    <NavigationDropdown eventKey="8" title="About" id="about" onClick={this.onClick}>
                        <NavTo to="/crew" title="The Crew"/>
                        <NavTo to="/impressions" title="Impressions" />
                        <NavTo to="/code-of-conduct" title="Code of Conduct"/>
                        <NavTo to="/cookies" title="Cookie Consent" />
                        <NavTo to="/privacy" title="Privacy Notice" />
                    </NavigationDropdown>
                </Nav>}
                {showLogin && <Nav pullRight>
                    <User eventKey="9" onClick={this.onClick}/>
                </Nav>}
            </Navbar.Collapse>
            </Container>
        </Navbar>
    }
}


export default Navigation;
