import {PRICES_HAS_ERRED, PRICES_IS_LOADING, PRICES_FETCHED_OK} from '../constants/prices'
import {pricesUrl} from "../../config";

export const pricesHasErred = (bool) => ({
    type: PRICES_HAS_ERRED,
    hasErred: bool
});


export const pricesIsLoading = (bool) => ({
    type: PRICES_IS_LOADING,
    isLoading: bool
});


export const pricesFetchOk = (prices) => ({
    type: PRICES_FETCHED_OK,
    prices
});


export function pricesFetchData() {
    return (dispatch) => {
        dispatch(pricesIsLoading(true));

        fetch(pricesUrl)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                dispatch(pricesIsLoading(false));

                return response;
            })
            .then((response) => response.json())
            .then((prices) => dispatch(pricesFetchOk(prices)))
            .catch(() => dispatch(pricesHasErred(true)));
    };
}