import {TALKS_HAS_ERRED, TALKS_IS_LOADING, TALKS_FETCHED_OK} from '../constants/talks'
import {talksUrl} from "../../config";

export const talksHasErred = (bool) => ({
    type: TALKS_HAS_ERRED,
    hasErred: bool
});

export const talksIsLoading = (bool) => ({
    type: TALKS_IS_LOADING,
    isLoading: bool
});

export const talksFetchOk = (talks) => ({
    type: TALKS_FETCHED_OK,
    talks
});


export function talksFetchData() {
    return (dispatch) => {
        dispatch(talksIsLoading(true));

        window.setTimeout(() => (fetch(talksUrl)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(talksIsLoading(false));

                return response;
            })
            .then((response) => response.json())
            .then((talks) => dispatch(talksFetchOk(talks)))
            .catch(() => dispatch(talksHasErred(true)))),100);
    };
}