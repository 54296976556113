import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Page, PagePanel} from "../../components/helpers/PagePanel";
import {ExternalLink} from "../../components/helpers/EasyLinks";
import BeersGallery from "./beers/BeersGallery";
import './About.css'
import pre2013 from './about/pre-2013.png'
import year2013 from './about/2013.png'
import year2014 from './about/2014.png'
import year2015 from './about/2015.png'
import year2016 from './about/2016.png'
import year2017 from './about/2017.png'
import year2018 from './about/2018.svg'
import year2019 from './about/2019.svg'
import lag from './about/laforge-and-glasius.jpg'

const imgStyle = {
  width: 129, height: 129
};

export const AboutPage = () => (
  <Page>
    <PagePanel title="About GR8Conf & JDK.io">
      <h2>Story-telling time</h2>
      {/*<Zoom>*/}
      <img className="pull-right img-thumbnail" style={{height: 130}} src={lag}
           alt="Søren Glasius & Guillaume Laforge"/>
      {/*</Zoom>*/}
      <p className="lead">
        In a galaxy far far away… in 2009, Søren invited Guillaume to speak about the Groovy programming language at a
        conference in Denmark.
        Discussing together, they started brainstorming about a short event to cover the various aspects of the
        language, the project and its
        ecosystem. This idea sparkle quickly overflowed into a more solid plant to create a dedicated conference fully
        dedicated to this
        ecosystem, as it was clear that there was way too much to cover than during a simple day workshop. This is how
        this idea of talking
        about
        “great” technologies, based off the Apache Groovy programming language turned into a full-blown conference named
        GR8Conf.
      </p>
      <p className="lead">
        For the past 11 years (not taking into account two years of corona lockdown), GR8Conf has provided a high-quality
          conference experience for the tight-knit Apache
        Groovy programming language community. This year, we are joining forces with JDK.io - an annual conference run
        by the Danish Java user group covering technologies relevant to the entire JVM. The combined conference will be
        known as GR8Conf & JDK.io and will focus on All Things Groovy and Java, with DevOps, Microservices and Frontend
        Technologies sprinkled in.

      </p>
      <Container>
        <Row>
          <Col xs={12} className="text-center">
            <img src={pre2013} alt="GR8Conf EU Pre 2013" style={imgStyle}/>
            <img src={year2013} alt="GR8Conf EU 2013" style={imgStyle}/>
            <img src={year2014} alt="GR8Conf EU 2014" style={imgStyle}/>
            <img src={year2015} alt="GR8Conf EU 2015" style={imgStyle}/>
            <img src={year2016} alt="GR8Conf EU 2016" style={imgStyle}/>
            <img src={year2017} alt="GR8Conf EU 2017" style={imgStyle}/>
            <img src={year2018} alt="GR8Conf EU 2018" style={imgStyle}/>
            <img src={year2019} alt="GR8Conf EU 2019" style={imgStyle}/>
          </Col>
        </Row>
      </Container>
      <p>
        At it's peak, GR8Conf had close to 200 attendees. Unfortunately this number has been decreasing the past few
        years, and for that reason we bring you GR8Conf & JDK.io. The cosy atmosphere of the conference and social
        events gives the attendees, speakers, sponsors, and others involved a great chance to interact.
      </p>
      <p>
        In 2017 we added DevOps as a focus area, to narrow the gap between the developers and operations, since it's
        often seen, that developers takes on the role to setup logging, orchestrating containers and deploying
        applications to the cloud.
      </p>
      <h3>GR8Conf World Wide</h3>
      <p>In 2010 GR8Conf US was created as a franchise, giving those who cannot travel to Europe a
        chance to get some GR8 content. For two years there were also an Indian version of GR8Conf.
          Both conference series has unfortunately been discontinued by the organizers there, leaving GR8Conf Eu standing
          as the last Groovy focused conference.
      </p>
      <h3>GR8Beer</h3>
      <p>
        Every year the  Crew brews beer, and who knows, perhaps there will be a 2022 version.
      </p>
      <h4>Brewing the beers</h4>
      <p>The gallery below shows the crew brewing and bottling the beers</p>
      <BeersGallery/>

    </PagePanel>
  </Page>
);


