import React, {Component} from 'react';
import {Page, PagePanel} from "../../components/helpers/PagePanel";
import {MailTo} from "../../components/helpers/EasyLinks";
import './Sponsors.css'
import {Redirect, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {SponsorsList} from "../../components/sponsors/SponsorsList";
import {SponsorView} from "../../components/sponsors/SponsorView";
import background from './sponsors-rollup.jpg';

class SponsorPage extends Component {

    render() {
        const name = this.props.name;
        if (this.props.sponsors && this.props.sponsors.length > 0 && name) {
            const group = this.props.sponsors.find(group => {
                return group.sponsors.some(sponsor => (sponsor.key === name))
            });
            if (group) {
                const sponsor = group.sponsors.find(sponsor => (sponsor.key === name));
                if (sponsor) {
                    return <div>
                        <SponsorView {...sponsor} group={group}/>
                    </div>
                }
            }
            return <Redirect to="/sponsors"/>
        }
        return <div>Loading....</div>
    }
}

const mapStateToProps = (state) => {
    return {
        sponsors: state.sponsors,
        hasErred: state.sponsorsHasErred
    };
};

const ConnectedSponsorList = connect(mapStateToProps)(SponsorsList);
const ConnectedSponsor = connect(mapStateToProps)(SponsorPage);


class SponsorsPage extends Component {

    render() {
        return (
            <Switch>
                <Route exact path="/sponsors" render={() => (
                    <Page>
                        <PagePanel bgImage={background} className='no-padding text-center' style={{minHeight: 350}}>
                            <div className="top" style={{backgroundColor: 'rgba(0,0,0,0.8)'}}>
                                <h1>Sponsors</h1>
                                <p className="lead">We're putting on a great show, and to do so we are looking for great sponsors.</p>
                                <p className="text-justify">
                                    If your company is looking to be part of Groovy and Java event, please get in touch
                                    with <MailTo email="sponsors@gr8conf.org"/> and we will provide you with the full sponsor program. We probably
                                    have a sponsor level that will fit your budget!
                                </p>
                            </div>
                        </PagePanel>
                        <ConnectedSponsorList/>
                    </Page>
                )}/>
                <Route path="/sponsors/:name" render={(props) => (
                    <Page>
                        <PagePanel style={{marginTop: 90, minHeight: 700}}>
                            <ConnectedSponsor name={props.match.params.name}/>
                        </PagePanel>
                    </Page>
                )}/>
            </Switch>
        );
    }
}

export default SponsorsPage
