/**
 * Find speakers for a given talk.
 * @param talk
 * @param speakersById
 * @returns {*}
 */
export const findSpeakers = (talk, speakersById) => {
    if(!talk || !talk.speakers || !speakersById) {
        return [];
    }
    return talk.speakers.map(speaker => (speakersById[speaker.id])).filter(value => (!!value));
};
