import {RATINGS_IS_LOADING, RATINGS_FETCHED, RATING_SAVING, RATING_SAVED, CONF_RATINGS_FETCHED, LEADERBOARD_FETCHED} from '../constants/ratings'

export const ratingsIsSaving = (state = [], action) => {
    switch (action.type) {
        case RATING_SAVING:
            return [...state, action.savingId];
        case RATING_SAVED:
            return state.filter(e => e !== action.savedId);
        default:
            return state;
    }
};

export const ratingsIsLoading = (state = [], action) => {
    switch (action.type) {
        case RATINGS_IS_LOADING:
            return action.isLoading;
        default:
            return state;
    }
};

export const ratings = (state = [], action) => {
    switch (action.type) {
        case RATINGS_FETCHED:
            return action.ratings;
        default:
            return state;
    }
};
export const conferenceRating = (state = [], action) => {
    switch (action.type) {
        case CONF_RATINGS_FETCHED:
            return action.rating;
        default:
            return state;
    }
};
export const leaderboard = (state = [], action) => {
    switch (action.type) {
        case LEADERBOARD_FETCHED:
            return action.leaderboard;
        default:
            return state;
    }
};
