import {SPONSORS_HAS_ERRED, SPONSORS_FETCHED_OK} from '../constants/sponsors'

export const sponsors = (state = [], action) => {
    switch (action.type) {
        case SPONSORS_FETCHED_OK:
            return action.sponsors;
        default:
            return state;
    }
};


export const sponsorsHasErred = (state = false, action) => {
    switch (action.type) {
        case SPONSORS_HAS_ERRED:
            return action.hasErred;

        default:
            return state;
    }
};
