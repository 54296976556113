import {OAUTH_CODE, OAUTH_CODE_CHECKING, OAUTH_CODE_STATUS, OAUTH_USER_AUTHORIZED} from '../constants/user'
import {conferenceId, oauthCodeUrl, oauthGetUserUrl} from "../../config";
import {post} from "./fetchHelper";

export const fetchingOAuthCode = (bool) => ({
    type: OAUTH_CODE_STATUS,
    fetchingCode: bool
});

export const setOAuthCode = (code) => ({
    type: OAUTH_CODE,
    code: code
});

export const checkOAuthCode = (bool) => ({
    type: OAUTH_CODE_CHECKING,
    checkingCode: bool
});

export const setOAuthUser = (user) => ({
    type: OAUTH_USER_AUTHORIZED,
    user: user
});

export function fetchOauthCode() {
    return (dispatch) => {

        dispatch(fetchingOAuthCode(true));
        fetch(oauthCodeUrl)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText)
                }
                dispatch(fetchingOAuthCode(false));
                return response;
            })
            .then((response) => response.json())
            .then((json) => dispatch(setOAuthCode(json.code)))
            .catch((e) => {
                console.error("Error", e)
            });
    }
}

export function getOAuthUser(user) {
    return (dispatch) => {
        dispatch(checkOAuthCode(true));
        post(oauthGetUserUrl, {...user, "conference.id": conferenceId})
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText)
                }
                dispatch(checkOAuthCode(false));
                return response;
            })
            .then((response) => response.json())
            .then((userResponse) => dispatch(setOAuthUser(userResponse)))
            .catch((e) => {
                console.error("Error", e)
            });
    }
}

export function clearOauthUser() {
    return (dispatch) => {
        localStorage.removeItem('user');
        dispatch(setOAuthUser({}));
        dispatch(fetchOauthCode());
    }
}