import React, {Component} from 'react';
import {connect} from "react-redux";
import {showLogin} from "../../config";
import PropTypes from 'prop-types'
import FA from 'react-fontawesome'
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {storeFavorite} from "../../data/actions/favorites";
import './Talks.css'
class Favorite extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        isLoggedIn: PropTypes.bool,
        user: PropTypes.object,
        favorites: PropTypes.array,
        isLoading: PropTypes.bool,
        isSaving: PropTypes.array,
        storeFavorite: PropTypes.func
    };

    render() {
        if (showLogin) {
            if (this.props.isSaving.includes(this.props.id)) {
                return <span className='fa-stack'><FA name="spinner" spin stack='1x'/></span>
            }
            let style = {
                color: '#fff'
            };
            let tooltipText = 'Click to favorite';
            let onClick = (() => {
            });
            if (this.props.isLoggedIn) {
                const isFavored = this.props.favorites.includes(this.props.id);
                onClick = (() => {
                    this.props.storeFavorite(this.props.id, !isFavored)
                });
                if (isFavored) {
                    style.color = '#ccc100';
                    tooltipText = 'Click to un-favorite';
                }
            } else {
                style.color = '#ccc';
                tooltipText = 'Login to use favorite';
            }
            const tooltip = (<Tooltip id={this.props.id}>{tooltipText}</Tooltip>);
            return <OverlayTrigger overlay={tooltip} placement="top">
            <span className='fa-stack gr8-stack' onClick={onClick}>
                <FA name='star' stack='1x' style={style}/>
                <FA name='star-o' stack='1x'/>
                </span>
            </OverlayTrigger>
        }

        return null
    }
}


const mapStateToProps = state => {
    return {
        isLoggedIn: state.oauth.isLoggedIn,
        user: state.oauth.user,
        favorites: state.favorites,
        isLoading: state.favoritesIsLoading,
        isSaving: state.favoritesIsSaving
    };
};
const mapDispatchToProps = {
    storeFavorite
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorite);

