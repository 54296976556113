import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {PagePanel} from '../../../components/helpers/PagePanel';
import '../Home.css'
import {ExternalLink} from "../../../components/helpers/EasyLinks";
import {showCFP} from "../../../config";

const About = () => (
    <PagePanel className="transparent information">
        <Container>
            <Row>
                <Col xs={12} sm={8} md={12} className="col-sm-push-2">
                    <h1>The Conference</h1>
                    <p className="lead"><strong>GR8Conf and JDK.io</strong> have joined <strong>forces</strong></p>
                    <p className="lead">
                        For the past 11 years (not counting a covid pandemic lockdown), GR8Conf has provided a
                        high-quality conference experience for the tight-knit Apache Groovy programming language
                        community. This year, we are joining forces with JDK.io - an annual conference run by the Danish
                        Java user group covering technologies relevant to the entire JVM. The combined conference will
                        be known as GR8Conf & JDK.io and will focus on All Things Groovy and Java, with DevOps,
                        Microservices and Frontend Technologies sprinkled in.
                    </p>
                </Col>
                {showCFP && <Col xs={8} md={12} className="col-xs-push-2">
                    <h1>The Call for Proposals is open!</h1>
                    <p className="lead">Go
                        to <ExternalLink link="https://cfp.gr8conf.org">cfp.gr8conf.org</ExternalLink> and
                        submit your proposal(s) for GR8Conf & JDK.io 2022 and be part of the awesome speakers line-up!
                    </p>
                </Col>}
                <Col xs={12} sm={8} md={12} className="col-sm-push-2">
                    <h1>Where and When</h1>
                    <p className="lead">Join us for <strong>two awesome</strong> conference days, mixed with
                        <strong> workshops</strong>, at <strong>CabInn Copenhagen</strong>
                        from <strong>November 16th</strong> to <strong>17th</strong>, 2022.</p>

                    <p className="lead">Your source for <strong>All Things Groovy and Java!</strong></p>
                </Col>
             </Row>
        </Container>
    </PagePanel>);

export default About;
