import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FieldGroup} from "./helpers/FieldGroup";
import {Form} from "react-bootstrap";
import StarRatingComponent from 'react-star-rating-component';
import FA from 'react-fontawesome';
import _ from 'lodash';

const commonPropTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    tooltip: PropTypes.string,
    onValueChanged: PropTypes.func
};

class Question extends Component {
    static propTypes = commonPropTypes;


}

export class TextAreaQuestion extends Question {
    static propTypes = commonPropTypes;
    static defaultProps = {
        value: ''
    };

    constructor(props) {
        super(props);
        this.name = props.id;
        this.state = {
            value: props.value || ''
        }
    }

    componentWillMount() {
        this.timer = null;
    }


    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        clearTimeout(this.timer);
        this.setState({value});

        this.timer = setTimeout(this.triggerChange, 750);
    };

    triggerChange = () => {
        if (this.props.onValueChanged) {
            this.props.onValueChanged(this.name, this.state.value);
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.value !== nextProps.value) {
            this.setState({value: nextProps.value || ''})
        }
    }

    render() {
        const props = _.omit(this.props, 'onValueChanged');
        const value = this.state.value;
        return <FieldGroup className="textarea" {...props} value={value} type="textarea" onChange={this.handleChange}/>
    }
}

export class StarRatingQuestion extends Question {
    static propTypes = {
        ...commonPropTypes,
        value: PropTypes.number,
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        hints: PropTypes.array
    };

    constructor(props) {
        super(props);

        let hints = props.hints || [];
        if (props.min === 0) {
            hints = ['', ...hints]
        }
        this.state = {
            value: props.value,
            help: props.help,
            hints: hints,
            hint: ''
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.state.value !== nextProps.value) {
            this.setState({value: nextProps.value});
        }
    }

    renderStarIcon = (index, value) => {
        return (
            <span>
                <FA name={index <= value ? 'heart' : 'heart-o'} size={"2x"}/>&nbsp;
            </span>
        )
    };

    onClick = (nextValue, prevValue) => {
        if (nextValue === 1 && prevValue === 1) {
            nextValue = 0;
        }
        this.setState({value: nextValue});
        if (this.props.onValueChanged) {
            this.props.onValueChanged(this.props.id, nextValue);
        }
    };

    setHint = (value) => {
        if (value < this.state.hints.length) {
            this.setState({hint: this.state.hints[value]})
        } else {
            this.setState({hint: ''})
        }
    };

    onHover = (nextValue) => {
        this.setHint(nextValue || 0);
    };

    onHoverOut = () => {
        this.setHint(this.state.value);
    };

    render() {
        const {id, min, max} = this.props;
        const props = _.omit(this.props, 'onValueChanged');

        return <FieldGroup {...props}>
            <div>
                <StarRatingComponent name={id}
                                     min={min}
                                     max={max}
                                     value={this.state.value}
                                     renderStarIcon={this.renderStarIcon}
                                     onStarClick={this.onClick}
                                     onStarHover={this.onHover}
                                     onStarHoverOut={this.onHoverOut}
                />
                <div className="star-hint text-muted">{this.state.hint}&nbsp;</div>
            </div>
        </FieldGroup>
    }
}

export class Questionnaire extends Component {
    static propTypes = {
        onDataChanged: PropTypes.func,
        data: PropTypes.object
    };

    static defaultProps = {
        onDataChanged: (() => {}),
        data: {}
    };


    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({data: nextProps.data});
    }

    handleValueChange = (name, value) => {
        const data = {...this.state.data, [name]: value};
        this.setState({data: data});
        this.props.onDataChanged(data);
    };

    render() {
        const {data} = this.state;
        return <Form horizontal>
            {React.Children.map(this.props.children, (child) => {
                    // For question add extra props for value and onValueChanged. Other components are just cloned
                    return React.cloneElement(child, child.type.name ? {
                        value: data[child.props.id],
                        onValueChanged: this.handleValueChange
                    } : {})
                }
            )}
        </Form>
    }

}
