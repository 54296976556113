import React, {Component} from 'react';
import './TheEnd.css'
import {Page, PagePanel} from "../../components/helpers/PagePanel";


class TheEnd extends Component {

    render() {
        return (
            <Page className="TheEnd">

                <PagePanel className="transparent">
                    <h1>GR8Conf is canceled</h1>
                    <p>After many thoughts and considerations, we regret to inform you that we are canceling GR8Conf
                        Europe and it will be discontinued.
                    </p>
                    <p>
                        The reasons are many, among others the recent two years with Covid-19 and the risk of new
                        restrictions due to new Covid-19 strains this coming fall. Another reason is the decline in the
                        number of attendees prior to 2020. Finally it is due to personal reasons. The conclusion is that
                        we will not move forward with another GR8Conf conference.
                        </p>
                    <p>
                        Looking back, we have had 11 great years, with lots of interesting talks, workshops,
                        presentations, beers and smalltalk in the corridors among the community members. It was a
                        pleasure to meet you all, and I personally hope you will stay in touch.
                        </p>
                    <p>
                        We want to thank all our sponsors, speakers, crew and attendees throughout the years. The
                        community is GR8 and we sincerely hope it will stay like that.
                        </p>
                    <p>
                        On behalf of the board of GR8Conf,
                        <br/>
                        Warm regards,
                        <br></br>
                        Søren Berg Glasius
                    </p>
                </PagePanel>
            </Page>
        );
    }

}

export default TheEnd;

