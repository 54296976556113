import React from 'react';
import {Navbar} from "react-bootstrap";
import {Container, Row, Col} from 'react-bootstrap';
import { PagePanel } from '../components/helpers/PagePanel';

import './Footer.css'
import {Link} from "react-router-dom";
import {ExternalLink} from "../components/helpers/EasyLinks";

const Navigation = () => (
    <div className="footer">

        <PagePanel className="orange-darkest no-padding">

            {false && <Container>
                <Row>
                    <Col sm={12} md={4}>
                        <h3>Who</h3>
                        <p>GR8Conf & JDK.io is organized by <ExternalLink link="http://twitter.com/sbglasius">Søren Berg Glasius</ExternalLink>,
                            backed by an awesome <Link to="/crew">team!</Link></p>
                    </Col>
                    <Col sm={12} md={4}>
                        <h3>History</h3>
                        <p>GR8Conf was started in 2009, thus making this the 12th edition, but also the first edition together with JDK.io. Read more <Link to="/about">here</Link></p>
                    </Col>
                    <Col sm={12} md={4}>
                        <h3>Get in touch</h3>
                        <p>If you need more information, please get in touch by
                            <a href="mailto:info@gr8conf.org?subject=GR8Conf & JDK.io" target="_blank"
                               rel="noopener noreferrer"> email</a>.</p>
                    </Col>
                </Row>
            </Container>}
        </PagePanel>
        <Navbar>
            <Navbar.Text style={{width: '100%', textAlign: 'center'}}>
                GR8Conf was brought to you by GR8 ApS
            </Navbar.Text>
        </Navbar>
    </div>
);

export default Navigation;
