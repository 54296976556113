import React from 'react';
import _ from "lodash";
import {Col, Container, Row} from "react-bootstrap";
import TweetEmbed from "react-tweet-embed";
import YouTube from "react-youtube";
import {Page, PagePanel} from "../../../components/helpers/PagePanel";
import './../About.css'
import LoadingDiv from "../../../components/helpers/LoaderDiv";

const tweets = [
    // 2019
    '1133962906784227328', '1133422058027782146', '1022496913436889094', '1133442574058967045', '1268156931081895936', '1133425209384558592',
    '1133420305588903938', '1134212685686628352',
    // 2018
    '1002527286787272705', '1002224186578952192',
    // 2017
    '870884341282476032', '870994940217749505', '870693812556845057', '870679259299291140', '870677685197914113', '870942238985117696',
    '870999297973784577', '870895422360997888',
    // 2016
    '738738397846470656', '738744364848033792', '738749704834535424', '738761195705012224', '738767153135026176', '738849586421338112',
    '738851537506603008', '738866841758650368', '738906339737501696', '738974376381579264', '738986449731780608', '739014044829548545',
    '739168371342774272', '739330836865126400', '739360067535962112',
];
const slicedTweets = _.chunk(_.shuffle(tweets), Math.floor(tweets.length / 2));
const youTubeOpts = {
    height: '390',
    width: '100%'
};
export const ImpressionsPage = () => (
    <Page>
        <PagePanel title="What others are saying">
            <p className="lead">Don't take it from us, here's what others has said:</p>
            <h2>Tweets about GR8Conf</h2>
                <Row>
                    {slicedTweets.map((slice, index) => (
                        <Col xs={12} sm={6} key={index}>
                        {slice.map((tweetId) => (
                                    <TweetEmbed id={tweetId}/>
                            ))}
                        </Col>

                    ))}
                </Row>
            <h2>Recap videos</h2>
            <Container>
                <Row>
                    <Col xs={12} className="col-sm-push-2"  sm={8}>
                        <h3>2017</h3>
                        <LoadingDiv>
                            <YouTube videoId='U4o0ubURORo' opts={youTubeOpts}/>
                        </LoadingDiv>
                    </Col>
                    <Col xs={12} className="col-sm-push-2"  sm={8}>
                        <h3>2016</h3>
                        <LoadingDiv>
                            <YouTube videoId='9gGzCIA8TQg' opts={youTubeOpts}/>
                        </LoadingDiv>
                    </Col>
                </Row>
            </Container>
        </PagePanel>
    </Page>
);
