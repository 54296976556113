import {FAVORITES_IS_LOADING, FAVORITES_FETCHED, FAVORITE_SAVING, FAVORITE_SAVED} from '../constants/favorites'

export const favoritesIsSaving = (state = [], action) => {
    switch (action.type) {
        case FAVORITE_SAVING:
            return [...state, action.savingId];
        case FAVORITE_SAVED:
            return state.filter(e => e !== action.savedId);
        default:
            return state;
    }
};

export const favoritesIsLoading = (state = [], action) => {
    switch (action.type) {
        case FAVORITES_IS_LOADING:
            return action.isLoading;
        default:
            return state;
    }
};

export const favorites = (state = [], action) => {
    switch (action.type) {
        case FAVORITES_FETCHED:
            return action.favorites;
        default:
            return state;
    }
};
