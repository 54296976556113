import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {withRouter} from "react-router-dom";
import FA from 'react-fontawesome';
import Prices from "../../components/prices/PricesTable";
import {ExternalLink, MailTo} from '../../components/helpers/EasyLinks'
import {Page, PagePanel} from "../../components/helpers/PagePanel";
import OCITraining from "../../components/prices/OCI";
import {ociTraining, registrationUrl} from "../../config";
import priceImage from '../../assets/images/itu-inside.jpg';
import "./Registration.css"

class RegistrationPage extends Component {
  render() {
    const {voucher} = this.props.match.params;
    return (
      <Page className="registration">
        <PagePanel className='no-padding text-center' bgImage={priceImage}>
          <div className="top">
            <h1>Registration</h1>
            <p className="lead">Register now for All Things Groovy</p>
            <Prices/>
            {voucher && <div className="well well-voucher well-lg">
              <p className="lead">
                Press this button to register with this voucher: {voucher}!
              </p>

              <ExternalLink link={registrationUrl + '?voucher=' + voucher} className="btn btn-buy btn-lg">
                <FA name="star"/> Use voucher! <FA name="star"/>
              </ExternalLink>

            </div>}

            {ociTraining && <OCITraining/>}

            <p className="lead">Tickets can be bought for two days only</p>
            <p className="lead">We offer discounts to groups of 5 or more people. Please contact <MailTo
              email="registration@gr8conf.org"/>.</p>
            {ociTraining &&
            <p>The OCI Training Days with Jeff Scott Brown are provided by OCI. GR8Conf Europe handles the
              payment. Inquiries about the training should be sent to <MailTo
                email="training@objectcomputing.com"/>.</p>}
          </div>
        </PagePanel>
        <PagePanel>
          <Container>
            <Row>
              <Col xs={12}>
                <p className="small small-print"><strong>The small print</strong>:</p>

                <p className="small small-print">
                  Prices here are in DKK and Euros excluding Danish VAT. The ticket system handles prices in Danish
                  Kroner (DKK) including Danish VAT. The price in Euros is an approximation, where we exchange rate of
                  7.50 DKK to approximately 1 €.</p>

                <p className="small small-print">Danish VAT is added to the ticket price during ticket processing.
                  Danish tax regulations does not allow us to give a VAT exempt (see <ExternalLink
                    link='https://skat.dk/skat.aspx?oid=2275656'>information the
                    Danish Customs and Tax Administration </ExternalLink> page)</p>
                <p className="small small-print">We use <ExternalLink
                  link="http://flexbillet.dk">Flexbillet</ExternalLink> as our
                  ticket broker. If you or your company is not be able to register and pay by credit
                  card, we have other options available. Please contact <MailTo email="registration@gr8conf.org"/> for
                  more information.</p>
                <p className="small small-print"><strong>Cancellation policy</strong>:</p>
                <p className="small small-print">
                  In case an attendee has to cancel her/his ticket, the following rules apply:
                </p>
                <Container>
                  <Row>
                    <Col xs={4} sm={2} className="small small-print">+8 weeks</Col>
                    <Col xs={8} sm={10} className="small small-print">
                      Full refund, because we understand that life works in mysterious ways.
                    </Col>
                    <Col xs={4} sm={2} className="small small-print">+6 weeks</Col>
                    <Col xs={8} sm={10} className="small small-print">
                      50% refund, because we ordered some swag for you
                    </Col>
                    <Col xs={4} sm={2} className="small small-print">+4 week</Col>
                    <Col xs={8} sm={10} className="small small-print">
                      25% refund, because it's getting really close to conference start by now
                    </Col>
                    <Col xs={4} sm={2} className="small small-print">less than four weeks</Col>
                    <Col xs={8} sm={10} className="small small-print">
                      No refund, sorry. We got everything ready for you.
                    </Col>
                  </Row>
                </Container>
                <p className="small small-print">We charge a DKK 550 / € 75 refund fee per order. In case someone else
                  can use the ticket, name changes on the tickets are free of charge.</p>
                <p className="small small-print">In case you need assistance, please contact <MailTo
                  email="info@gr8conf.org"/> or click the chat window below.</p>

              </Col>
            </Row>
          </Container>
        </PagePanel>
      </Page>
    );
  }
}

export default withRouter(RegistrationPage)
