import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./PagePanel.css";

export class Page extends Component {
    render() {
        const style = this.props.style || {};
        const className = 'page ' + (this.props.className || '');

        return <div className={className} style={style}>
            {this.props.children}
        </div>
    }

}

export class PagePanel extends Component {
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        bgImage: PropTypes.string,
        title: PropTypes.string,
        fluid: PropTypes.bool
    };
    static defaultProps = {
        className: '',
        style: {},
        fluid: false
    };

    render() {
        let style = this.props.style || {};
        let className = 'page-panel ' + (this.props.className || '');
        if (this.props.bgImage) {
            className = className + ' bg-image';
            style = {...style, backgroundImage: 'url(' + this.props.bgImage + ')'};
        }

        return <div className={className} style={style}>
            <div className={"container" + (this.props.fluid ? '-fluid' : '')}>
                {this.props.title && <h1 className="title">{this.props.title}</h1>}

                {this.props.children}
            </div>
        </div>
    }
}
