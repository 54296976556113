import React from 'react'
import {Alert, Col, Container, Row} from "react-bootstrap";
import FA from 'react-fontawesome'

export const Disclaimer = (props) => (
    <Container style={{marginTop: 30}}>
        <Row>
            <Col xs={12}>
                <Alert className="alert-gr8conf">
                    <FA name="info-circle"/> Organizing a conference deals with real people (they're real awesome too!). That
                    means, that things beyond GR8Conf's reach happens, things that can influence the list of speakers, the talks and the agenda.
                    In other words, the program is dynamic, and might change without notice, but we do our best to keep it as static as possible.
                </Alert>
            </Col>
        </Row>
    </Container>
);
