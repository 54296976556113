import React, {Component} from 'react';

import './Agenda.css';
import PropTypes from 'prop-types';
import {AgendaDates} from "./DatesPanel";
import {dateStringToMoment, momentToDateString} from "../helpers/DateHelpers";
import {AgendaDay} from "./AgendaDay";
import TagList from "../tags/TagFilter";

export class AgendaPanel extends Component {
    static propTypes = {
        agenda: PropTypes.array.isRequired,
        day: PropTypes.object,
        onDateChanged: PropTypes.func,
        simple: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = AgendaPanel.makeState(props);
        this.onDateChanged = this.onDateChanged.bind(this)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(AgendaPanel.makeState(nextProps));
    }

    static makeState(props) {
        const currentDate = dateStringToMoment(localStorage.getItem('agenda-current-day'));
        const agenda = props.agenda || [];
        const firstAgendaDay = agenda.length > 0 ? agenda[0].date : undefined;
        let date = props.day ? props.day : (currentDate ? currentDate : firstAgendaDay);
        // If the date is from last year, update it to this year
        if (date && firstAgendaDay && date.year() !== firstAgendaDay.year()) {
            date = firstAgendaDay
        }

        return {
            dates: agenda.map(day => (day.date)),
            date: date,
            agenda: agenda,
            simple: props.simple || false
        }
    }

    onDateChanged = (date) => {
        if (this.state.date !== date) {
            this.setState({date: date});
            localStorage.setItem('agenda-current-day', momentToDateString(date));
        }
    };

    render() {
        if (!this.state.agenda || this.state.agenda.length === 0) {
            return <div/>
        }
        const day = this.state.agenda.find(day => (day.date.isSame(this.state.date)));
        return <div className="agenda-panel">
            {!this.state.simple &&
            <div>
                <AgendaDates dates={this.state.dates} date={this.state.date} onDateChange={this.onDateChanged}/>
                <div style={{zIndex: 1000}}>
                    <TagList/>
                </div>
            </div>
            }
            <AgendaDay day={day} hourHeight={this.state.simple ? 80 : 135} simple={this.state.simple}/>
        </div>
    }
}
