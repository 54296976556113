import React from 'react'
import ociTraining from '../../assets/images/oci-training.png'
import './OCI.css'
import {ExternalLink} from "../helpers/EasyLinks";

const OCITraining = () => (
    <div className="oci">
        <p className="lead">Add to the experience.</p>
        <p><ExternalLink link="https://objectcomputing.com/resources/events/conferences/gr8conf-eu/micronaut-from-iot-to-gcp"> <img src={ociTraining} alt="Groovy training by OCI"/></ExternalLink></p>
        <p><strong>Note: </strong>Regular price for OCI training is €385. Discounts may apply!</p>
    </div>
);

export default OCITraining;
