import React, {Component} from 'react';
import TalkInfo from "./TalkInfo"
import {Container, Row, Col} from 'react-bootstrap';
import {getTalkIdsFromTags} from "../tags/TagHelper";
import PropTypes from 'prop-types';

class TalksList extends Component {
    static propTypes = {
        talks: PropTypes.array.isRequired,
        tags: PropTypes.object.isRequired
    };

    render() {
        const {talks, tags} = this.props;
        const taggedTalks = getTalkIdsFromTags(tags ? tags.active : []);
        const activeTalks = talks.filter(talk => {
            return taggedTalks.length === 0 || taggedTalks.findIndex(id => talk.id === id) !== -1
        });

        return (
            <Container>
                <Row>
                    {
                        activeTalks.map((talk, index) => (
                        <Col xs={12} key={index}>
                            <TalkInfo talk={talk}/>
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    }
}


export default TalksList;


