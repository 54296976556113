import React, {Component} from 'react';
import './App.css';
import SimpleAgendaPage from "./pages/simpleAgenda/SimpleAgendaPage";
import {Route, Router} from "react-router-dom";
import history from './history';

class AgendaApp extends Component {
    render() {
        return (
            <div className='agenda-app'>
                <Router history={history}>
                    <Route
                        path="/:day?"
                        component={SimpleAgendaPage}
                    />

                </Router>
            </div>
        );
    }
}

export default AgendaApp;
