import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {Page, PagePanel} from "../../components/helpers/PagePanel";
import {AgendaPanel} from "../../components/agenda/AgendaPanel";
import {dateStringToMoment} from "../../components/helpers/DateHelpers";
import Leaderboard from '../../components/leaders/Leaderboard';
import './SimpleAgenda.css';
import moment from 'moment'
import {FeaturedSponsors} from "../../components/sponsors/SponsorsPanel";
import {showLeaderboard} from "../../config";

class SimpleAgendaPage extends Component {

    render() {
        if(this.props.isLoading) {
            return <div>Loading agenda...</div>
        }
        console.log(moment().format('yyyy-MM-DD'));
        const day = dateStringToMoment(this.props.match.params.day || moment().format('YYYY-MM-DD'));
        const header = "Agenda for " + day.format("MMMM DD");
        return <Page>
            <PagePanel title={header} className="simple-agenda" fluid titleStyle={{background: 'blue'}}>
                <Container fluid>
                    <Row>
                        <Col xs={12} lg={10}>
                            <AgendaPanel agenda={this.props.agenda} day={day} simple={true}/>
                        </Col>
                        <Col className="d-sm-none" lg={2}>
                            {showLeaderboard && <Leaderboard/>}

                            <FeaturedSponsors simple/>
                        </Col>
                    </Row>
                </Container>
            </PagePanel>

        </Page>
    }
}

const mapStateToProps = state => {
    return {agenda: state.agenda, isLoading: state.agendaIsLoading};
};

export default connect(mapStateToProps)(SimpleAgendaPage);
