import React from 'react';
import {Link} from "react-router-dom";

export const RenderSpeakersLinks = (props) => {
    const speakers = props.speakers;
    return <span>{speakers.map((speaker, index) => {
        let space = speakers.length === 1 ? '' : ', ';
        if (speakers.length > 1) {
            if (speakers.length - 2 === index) {
                space = ' and ';
            } else if (speakers.length - 1 === index) {
                space = ''
            }
        }
        return <span key={speaker.id}>
            <Link to={ speaker.twitter ? "/speakers/" + speaker.twitter : "/speakers/byid/" + speaker.id}>{speaker.name}</Link>{space}
        </span>
    })}
    </span>;
};
