import React, {Component} from 'react';
import {connect} from "react-redux";
import Select from "react-select";
import {setActiveTags} from "../../data/actions/tags";
import './TagFilter.css'

class TagFilter extends Component {
    render() {
        if (this.props.isLoading || !this.props.tags || !this.props.tags.all) {
            return <div/>
        }
        const {tags, placeholder} = this.props;
        const options = tags.all.map(tag => ({value: tag.tag, label: tag.tag + " ("+tag.talks.length+")", talks: tag.talks}));

        return <Select name="tags"
                       value={tags.active}
                       options={options}
                       multi={true}
                       placeholder={placeholder || "Highlight your favorite tag(s)"}
                       onChange={this.props.setActiveTags}/>
    }
}

const mapStateToProps = state => {
    return {tags: state.tags, isLoading: state.tagsIsLoading};
};
const mapDispatchToProps = {
    setActiveTags
};

export default connect(mapStateToProps, mapDispatchToProps)(TagFilter);

