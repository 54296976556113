/**
 * CSS Controlled background image slideshow.
 *
 * Created by:
 * Søren Berg Glasius <soeren@glasius.dk>
 *
 * Credits and inspired by:
 * CSS Stylesheet and animations: https://tympanus.net/codrops/2012/01/02/fullscreen-background-image-slideshow-with-css3/
 * injectStyle: from this gist: https://gist.github.com/yamadayuki/f1ea9ccacad7f1c140457b5877fb54cc
 */


import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './background-slideshow.css';

const injectStyle = (style) => {
    const styleElement = document.createElement('style');
    document.head.appendChild(styleElement);

    let styleSheet = styleElement.sheet;
    styleSheet.insertRule(style, styleSheet.cssRules.length);
};

class BackgroundSlideshow extends Component {
    constructor(props) {
        super(props);
        const totalImages = this.props.images.length;
        const totalDuration = this.props.totalDuration || this.props.imageDuration * totalImages;

        const imageDuration = totalDuration / totalImages;
        const fadeInTime = this.props.fadeInTime || Math.ceil(imageDuration / 10);
        const holdTime = imageDuration - fadeInTime;
        const fadeOutTime = this.props.fadeOutTime || fadeInTime * 2;

        const oneSecondInPercent = 100 / totalDuration;
        const fadeInEnd = Math.ceil(fadeInTime * oneSecondInPercent);
        const holdInEnd = Math.ceil(holdTime * oneSecondInPercent);
        const fadeOutEnd = Math.ceil((imageDuration + fadeOutTime) * oneSecondInPercent);

        const keyframesStyle = `
            @keyframes imageAnimation { 
                0% { opacity: 0; animation-timing-function: ease-in; }
                ` + fadeInEnd + `% { opacity: 1; animation-timing-function: ease-out; }
                ` + holdInEnd + `% { opacity: 1 }
                ` + fadeOutEnd + `% { opacity: 0 }
                100% { opacity: 0 }
            }        
        `;

        injectStyle(keyframesStyle);
        const position = this.props.fixed ? 'fixed' : 'absolute';

        this.state = {
            style: {
                backgroundStyle: { ...this.props.backgroundStyle,
                    WebkitAnimation: 'imageAnimation '+totalDuration+'s linear infinite 0s',
                },
                container: { ...this.props.style,
                    position: position,
                },
            },
            imageDuration: Math.round(totalDuration / totalImages)
        }
    }

    render() {
        const {style, imageDuration} = this.state;
        const className = 'cb-slideshow '+this.props.className;
        const backgroundClassName = '' + this.props.backgroundClassName;

        const backgroundImages = this.props.images.map((bgImage, index) => {
            const imageUrl = 'url('+bgImage+')';
            const animationDelay = (index * imageDuration)+'s';
            const backgroundStyle = {...style.backgroundStyle, backgroundImage: imageUrl, animationDelay: animationDelay};
            return <li key={index}>
                <span className={backgroundClassName} style={backgroundStyle}/>
            </li>
        });

        return (<ul className={className} style={style.container}>
            {backgroundImages}
        </ul>);
    }
}

BackgroundSlideshow.propTypes = {
    images: PropTypes.array.isRequired,
    totalDuration: PropTypes.number,
    fadeInTime: PropTypes.number,
    imageDuration: PropTypes.number,
    fadeOutTime: PropTypes.number,
    style: PropTypes.object,
    className: PropTypes.string,
    backgroundStyle: PropTypes.object,
    backgroundClassName: PropTypes.string
};

BackgroundSlideshow.defaultProps = {
    totalDuration: undefined,
    imageDuration: 5, // Seconds
    style: {},
    className: '',
    backgroundStyle: {},
    backgroundClassName: ''
};


export default BackgroundSlideshow;

