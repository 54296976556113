import {AGENDA_HAS_ERRED, AGENDA_IS_LOADING, AGENDA_FETCHED_OK} from '../constants/agenda'
import {dateStringToMoment, timeStringToMoment, calculateDuration} from "../../components/helpers/DateHelpers";
import {agendaUrl} from "../../config";

export const agendaHasErred = (bool) =>({
        type: AGENDA_HAS_ERRED,
        hasErred: bool
    });

export const agendaIsLoading = (bool) => ({
    type: AGENDA_IS_LOADING,
    isLoading: bool
});

export const agendaFetchOk = (agenda) => ({
    type: AGENDA_FETCHED_OK,
    agenda
});

const iconMap = {
    "icon-ticket": "ticket",
    "icon-pause": "pause",
    "icon-coffee": "coffee",
    "icon-food": "cutlery"
};

const mapIcon = (icon) => iconMap[icon];

// Adding durations to days and tracks, and mapping to icon to a font-awesome icon for breaks
const enrichAgenda = (agenda) => (agenda.map(day => {
    return {
        ...day,
        duration: calculateDuration(day.start, day.end),
        date: dateStringToMoment(day.day),
        startTime: timeStringToMoment(day.start),
        endTime: timeStringToMoment(day.end),
        columns: day.tracks.filter(track => (!(track.breaks || track.allColumns))).length,
        tracks: day.tracks.map(track => {
            return {
                ...track,
                startTime: timeStringToMoment(track.start),
                endTime: timeStringToMoment(track.end),
                duration: calculateDuration(track.start, track.end),
                slots: track.slots.map(slot => {
                    return {
                        ...slot,
                        startTime: timeStringToMoment(slot.start),
                        endTime: timeStringToMoment(slot.end),
                        faIcon: mapIcon(slot.icon)
                    }
                })
            }
        })
    }
}));

export function agendaFetchData() {
    return (dispatch) => {
        dispatch(agendaIsLoading(true));

        window.setTimeout(() => (fetch(agendaUrl)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(agendaIsLoading(false));

                return response;
            })
            .then((response) => response.json())
            .then((json) => enrichAgenda(json))
            .then((agenda) => dispatch(agendaFetchOk(agenda)))
            .catch(() => dispatch(agendaHasErred(true)))), 100);
    };
}