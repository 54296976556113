import React, {Component} from 'react';
import {Button, ListGroup, ListGroupItem, Card} from 'react-bootstrap';
import FA from 'react-fontawesome';
import {connect} from "react-redux";
import './PricesTable.css'
import {ExternalLink} from '../helpers/EasyLinks'
import { registrationUrl} from "../../config";

class PriceTable extends Component {
    constructor(props) {
        super(props);

        this.price = props.price;
        this.link = registrationUrl + (this.price.discountCode ? "?voucher="+this.price.discountCode : '');
    }

    render() {
        return <div className={"price-table " + (this.price.soldOut ? " sold-out" : "") + (this.price.open ? "" : " not-open")}>
            <Card>
                <Card.Header>
                    <Card.Title className="name h2">
                        {this.price.name}
                    </Card.Title>
                </Card.Header>
                {this.price.discount && <Card.Body className={"description "}>{this.price.discount}</Card.Body>}
                {this.price.description && <Card.Body className={"description "}>{this.price.description}</Card.Body>}
                <ListGroup className="prices">
                  <ListGroupItem className={'price0'}>
                    <div className="pull-right text-right">
                      DKK {this.price.priceDKK}<br/>€ {this.price.priceEuro}</div>
                    <div className="text-left">Full conference pass</div>
                  </ListGroupItem>
                </ListGroup>
                <Card.Body className="button">
                    {this.price.open &&
                    <ExternalLink link={this.link} className="btn btn-buy"><FA name="star"/> Buy now <FA name="star"/></ExternalLink>
                    }
                    {!this.price.open && !this.price.soldOut &&
                    <Button className="btn-info" disabled={true}>Not Open Yet</Button>
                    }
                    {!this.price.open && this.price.soldOut &&
                    <Button className="btn-warning" disabled={true}>Sold Out</Button>
                    }
                </Card.Body>
            </Card>
        </div>;
    }
}


class PriceTables extends Component {
    render() {
        return <div className="price-tables">
            {this.props.prices && this.props.prices.map((price, index) => (
                <PriceTable price={price} key={index}/>
            ))}
        </div>
    }
}

const mapStateToProps = state => {
    return {prices: state.prices};
};

const Prices = connect(mapStateToProps)(PriceTables);


export default Prices;
