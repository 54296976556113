import {RATINGS_IS_LOADING, RATING_SAVED, RATING_SAVING, RATINGS_FETCHED, CONF_RATINGS_FETCHED, LEADERBOARD_FETCHED} from '../constants/ratings'
import {confRatingsGetUrl, confRatingsPutUrl, leaderboardUrl, ratingsGetUrl, ratingsPutUrl} from "../../config";
import {getUserData} from "../userdata";
import {get, post} from "./fetchHelper";

export const fetchingRatings = (bool) => ({
    type: RATINGS_IS_LOADING,
    isLoading: bool
});

export const storingRating = (id) => ({
    type: RATING_SAVING,
    savingId: id
});

export const storingRatingDone = (id) => ({
    type: RATING_SAVED,
    savedId: id
});

export const setRatings = (ratings) => ({
    type: RATINGS_FETCHED,
    ratings: ratings
});

export const setConferenceRating = (rating) => ({
    type: CONF_RATINGS_FETCHED,
    rating: rating
});

export const setConferenceLeaderboard = (leaderboard) => ({
    type: LEADERBOARD_FETCHED,
    leaderboard: leaderboard
});

export function fetchRatings() {
    return (dispatch, getState) => {
        const userData = getUserData(getState);
        if (userData) {
            dispatch(fetchingRatings(true));
            get(ratingsGetUrl, userData)
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText)
                    }
                    dispatch(fetchingRatings(false));
                    return response;
                })
                .then((response) => response.json())
                .then((ratings) => dispatch(setRatings(ratings)))
                .catch((e) => {
                    console.error("Error", e);
                    dispatch(setRatings([]))
                });
        } else {
            console.log("Not logged in")
        }
    }
}


export function storeRating(id, data) {
    return (dispatch, getState) => {
        const userData = getUserData(getState, {'talk.id': id, ...data});
        if (userData) {
            dispatch(storingRating(id));
            post(ratingsPutUrl, userData)
                .then((response) => {
                    dispatch(storingRatingDone(id));
                    if (!response.ok) {
                        throw Error(response.statusText)
                    }
                    dispatch(fetchRatings())
                })
                .catch((e) => {
                    console.error("Error", e)
                });
        }
    }
}

export function fetchConferenceRatings() {
    return (dispatch, getState) => {
        const userData = getUserData(getState);
        if (userData) {
            dispatch(fetchingRatings(true));
            get(confRatingsGetUrl, userData)
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText)
                    }
                    dispatch(fetchingRatings(false));
                    return response;
                })
                .then((response) => response.json())
                .then((rating) => dispatch(setConferenceRating(rating)))
                .catch((e) => {
                    console.error("Error", e);
                    dispatch(setRatings([]))
                });
        } else {
            console.log("Not logged in")
        }
    }
}

export function storeConferenceRating(data) {
    return (dispatch, getState) => {
        const userData = getUserData(getState, data);
        if (userData) {
            post(confRatingsPutUrl, userData)
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText)
                    }
                    // dispatch(fetchConferenceRatings())
                })
                .catch((e) => {
                    console.error("Error", e)
                });
        }
    }
}

export function fetchConferenceLeaderboard() {
    return (dispatch, getState) => {
        get(leaderboardUrl)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText)
                }
                setTimeout(() => (dispatch(fetchConferenceLeaderboard())), 5000)
                return response;
            })
            .then((response) => response.json())
            .then((json) => dispatch(setConferenceLeaderboard(json)))
            .catch((e) => {
                console.error("Error", e);
            });
    }
}

