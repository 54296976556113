import React, {Component} from 'react'
import {connect} from "react-redux";
import {ProgressBar} from "react-bootstrap";
import "./Leaderboard.css"

class Leaderboard extends Component {
    render() {
        const {leaderboard} = this.props;
        const maxScore = leaderboard[0].score;

        return <div className="leaderboard">
            <h2>Ratings</h2>
            <h3>Leader board</h3>
            {leaderboard.slice(0,10).map(user => {
                const progress = user.score / maxScore * 100;
                return <div key={user.id}>
                    <ProgressBar now={progress} label={user.name}/>
                </div>
            })}
        </div>
    }
}

const mapStateToProps = (state) => ({
    leaderboard: state.leaderboard
});

export default connect(mapStateToProps)(Leaderboard)