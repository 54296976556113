import {AGENDA_HAS_ERRED, AGENDA_IS_LOADING, AGENDA_FETCHED_OK} from '../constants/agenda'

export const agenda = (state = [], action) => {
    switch (action.type) {
        case AGENDA_FETCHED_OK:
            return action.agenda;
        default:
            return state;
    }
};

export function agendaHasErred(state = false, action) {
    switch (action.type) {
        case AGENDA_HAS_ERRED:
            return action.hasErred;

        default:
            return state;
    }
}

export function agendaIsLoading(state = false, action) {
    switch (action.type) {
        case AGENDA_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
}

