import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Page, PagePanel} from "../../../components/helpers/PagePanel";
import {ExternalLink} from "../../../components/helpers/EasyLinks";
import FA from 'react-fontawesome';
import anders from './anders.jpg';
import brian from './brian.jpg';
import christian from './christian.jpg';
import jacob from './jacob.jpg';
import jenn from './jenn.jpg';
import morten from './morten.jpg';
import marknellemann from './marknellemann.jpg';
import niels from './null.jpg';
import henning from './henning.png';
import soeren from './soeren.jpg';
import stiwi from './stiwi.jpg';
import thomas from './thomas.jpg';
import theCrew from './the-crew.jpg'

const crew = [
    {
        "name": "Søren Berg Glasius",
        "employer": "GR8Conf / Gennemtænkt IT",
        "link": "http://ociweb.com",
        "image": soeren,
        "twitter": "sbglasius",
        "email": "sbglasius@gr8conf.org",
        "title": "Organizer and co-Founder"
    },
    {
        "name": "Brian Johnsen",
        "employer": "Gennemtænkt IT",
        "link": "http://gennemtaenkt.dk",
        "image": brian,
        "twitter": "brianjohnsendk ",
        "email": "brian@gr8conf.org",
        "title": "GR8 ApS Board Member"
    },
    {
        "name": "Morten Kristiansen",
        "employer": "Gennemtænkt IT",
        "link": "http://gennemtaenkt.dk",
        "image": morten,
        "twitter": "mlkristiansen",
        "email": "morten@gr8conf.org",
        "title": "GR8 ApS Board Member"
    },
    {
        "name": "Jacob Aae Mikkelsen",
        "employer": "CardLay A/S",
        "link": "http://cardlay.com",
        "image": jacob,
        "twitter": "JacobAae",
        "email": "jacob@gr8conf.org"
    },
    {
        "name": "Thomas Rasmussen",
        "employer": "Gennemtænkt IT",
        "link": "http://gennemtaenkt.dk",
        "image": thomas,
        "twitter": "dauer",
        "email": "thomas@gennemtaenkt.dk"
    },
    {
        "name": "Stiwi Gabriel Courage",
        "employer": "OpenSquare",
        "link": "http://opensquare.dk/",
        "image": stiwi,
        "twitter": "StiwiCourage",
        "email": "info@courage.it"
    },
    {
        "name": "Jennifer Strater",
        "employer": "Freelance",
        "link": "http://www.jennstrater.com/",
        "image": jenn,
        "twitter": "codeJENNerator",
        "email": "jstrater@gr8ladies.org"
    },
    {
        "name": "Niels Ull Harremoës",
        "employer": "Null Consult ApS",
        "link": "http://www.linkedin.com/in/nielsull",
        "image": niels,
        "twitter": "Null_dk",
        "email": "niels@gr8conf.org"
    },
    {
        "name": "Mark Nellemann",
        "employer": "Agillic A/S",
        "link": "https://www.agillic.com/",
        "twitter": "mark_nellemann",
        "image": marknellemann,
        "email": "mark.nellemann@gmail.com"
    },
    {
        "name": "Anders Kristian Andersen",
        "employer": "Freelance",
        "link": "http://www.gr8conf.eu/",
        "image": anders,
        "twitter": "anderskristian",
        "email": "aka@myself.com"
    },
    {
        "name": "Henning Hørslev Hansen",
        "employer": "DocuBizz",
        "link": "https://www.docubizz.com",
        "image": henning,
        "twitter": "henninghhansen",
        "email": "henning@gr8conf.org"
    },
    {
        "name": "Christian Damsgaard",
        "employer": "Damsgaard Data Aps",
        "link": "https://damsgaard.biz/",
        "image": christian,
        "twitter": "Damzgaard",
        "email": "christian@gr8conf.org"
    }
];

class CrewMember extends Component {
    render() {
        const {name, employer, twitter, image, title} = this.props.member;
        return <div style={{marginTop: 10}}>
            <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                <img className="img-thumbnail" style={{width: 100}} src={image} alt={name}/>
            </div>
            <div style={{marginLeft: 5, display: 'inline-block'}}>
                <h4>{name}</h4>
                <h5>{employer}</h5>
                <p>{title}</p>
                <ExternalLink link={"https://twitter.com/" + twitter}><FA name="twitter"/> {twitter}</ExternalLink>
            </div>
        </div>
    }
}

export const TheCrewPage = () => (
    <Page>
        <PagePanel title="The Crew">
            <p className="lead text-center">These fine people put on GR8Conf & JDK.io 2022 for you</p>

            <Container>
                <Row>
                    <Col xs={12} className="col-sm-push-2"  sm={8}>
                        <img src={theCrew} alt="The Crew" style={{width: '100%'}}/>
                    </Col>
                </Row>
                <Row>
                    <h2>The crew</h2>
                    {crew.map((member, index) => (
                        <Col xs={12} sm={6} lg={4} key={index}>
                            <CrewMember member={member}/>
                        </Col>
                    ))}
                </Row>
            </Container>


        </PagePanel>
    </Page>
);
