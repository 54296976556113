import {FAVORITES_IS_LOADING, FAVORITE_SAVING, FAVORITE_SAVED, FAVORITES_FETCHED} from '../constants/favorites'
import {favoritesGetUrl, favoritesPutUrl} from "../../config";
import {getUserData} from "../userdata";
import {get, post} from "./fetchHelper";

export const fetchingFavorites = (bool) => ({
    type: FAVORITES_IS_LOADING,
    isLoading: bool
});

export const storingFavorite = (id) => ({
    type: FAVORITE_SAVING,
    savingId: id
});

export const storingFavoriteDone = (id) => ({
    type: FAVORITE_SAVED,
    savedId: id
});

export const setFavorites = (favorites) => ({
    type: FAVORITES_FETCHED,
    favorites: favorites
});

export function fetchFavorites() {
    return (dispatch, getState) => {
        const userData = getUserData(getState);
        if (userData) {
            dispatch(fetchingFavorites(true));
            get(favoritesGetUrl, userData).then((response) => {
                dispatch(fetchingFavorites(false));
                return response;
            })
                .then((response) => response.json())
                .then((favorites) => dispatch(setFavorites(favorites)))
                .catch((e) => {
                    console.error("Error", e);
                    dispatch(setFavorites([]))
                });
        } else {
            console.log("Not logged in")
        }
    }
}


export function storeFavorite(id, status) {
    return (dispatch, getState) => {
        const userData = getUserData(getState, {"talk.id": id, status: status});
        if (userData) {
            dispatch(storingFavorite(id));
            post(favoritesPutUrl, userData)
                .then((response) => {
                    dispatch(storingFavoriteDone(id));
                    if (!response.ok) {
                        throw Error(response.statusText)
                    }
                    dispatch(fetchFavorites());
                })
                .catch((e) => {
                    console.error("Error", e);
                });
        }
    }
}

