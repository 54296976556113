import React from 'react';
import {Page, PagePanel} from "../../../components/helpers/PagePanel";
import {ExternalLink, MailTo} from "../../../components/helpers/EasyLinks";
import {Link} from "react-router-dom";
import "./CFP.css"

export const CFPPage = () => (
  <Page>
    <PagePanel title="GR8Conf & JDK.io CFP">
      <p className="lead"> The GR8Conf &amp; JDK.io Call For Papers is open! Submit your talks <ExternalLink
        link="https://cfp.gr8conf.org">here</ExternalLink>.
      </p>
      <p className="text-center">
        <ExternalLink className="btn btn-lg btn-primary" link="http://cfp.gr8conf.org" target="_blank">
          Submit your talks!
        </ExternalLink></p>
      <h1>GR8Conf and JDK.io are joining forces</h1>
        <p className="lead">For the past 11 years (not counting two years of Corona pandemic), GR8Conf has provided a
            high-quality conference experience for the tight-knit Apache Groovy programming language community. To
            expand the reach of the conference, encourage cross-pollination across the JVM, and share some of the
            logistical overhead, in 2022 we are joining forces with JDK.io - an annual conference run by the Danish Java
            user group covering technologies relevant to the entire JVM. The new, joint event GR8Conf & JDK.io will take
            place at Cabinn Copenhagen, November 16th and 17th 2022. The conference has a global outreach, where the
            latest edition had attendees from 25 countries world wide.</p>
      <h2>TL;DR;</h2>
        <p>The audience is interested in All Things Groovy and Java. Timeslots are 20 or 45 minutes, keynotes 50
            minutes. Technical talks are preferred. We love code! Promotional/commercial talks will be rejected. T&amp;E
            is covered for speakers (restrictions apply), unless of course your company can and will pay! Speakers will
            get a complementary VIP conference pass and an invitation to speakers dinner. We have a Code of Conduct. You
            really should read the full document.</p>
      <h2>The Audience</h2>
        <p>Historically, the GR8Conf audience has consisted of a mix of Java and Groovy software developers from Europe
            and beyond. While hard to quantify exactly, there has been a sizable contingent of GR8 technology newbies,
            and a roughly equal-sized contingent of experienced, practicing Groovy developers. Roughly one-third use
            Groovy-based technologies as their primary technology. We encourage talks that cover Groovy, Java, DevOps,
            and related technologies relevant to the JVM.</p>
      <h2>Practical information</h2>
        <p>The time slots for a presentation at GR8Conf & JDK.io are either 20 minutes or 45 minutes, with a main focus
            on 45 minutes talks.</p>
        <p>As the conference covers Groovy and Java, there will initially be one Groovy track and one Java track. We
            encourage technical talks, supporting material, and references to provide conference attendees with
            additional details. </p>
        <p>Presentations that are little more than product or personal promotion (commercialism), will be rejected. If
            you want to give a commercial/promotional presentation, contact us about sponsorship. If you are uncertain
            whether or not your presentation will be deemed as commercial, please contact us before submission.</p>
        <p>We will not reject any proposal a priori as long as it complies with these general guidelines. Preference
            will be given to those that are aligned with one or more of the topics listed under Conference topics or
            Workshop topics. Preference will also be given to those that submit more than one presentation.</p>
      <h2>General topic guidelines</h2>
      <p>Now that the conference covers technologies in the Groovy and Java ecosystems, the number of potential
        topics has greatly increased., but we still prefer talks that have a technical foundation, and we love code
        examples!</p>
      <p>While GR8Conf & JDK.io is not a dedicated DevOps conference, DevOps is the everyday life of a modern
        developer and a relevant topic for our audience. We are actively looking for talks on continuous integration
        tools, build tools, continuous deployment tools, deployment platforms and more that relate to deployment
        with or on JVM related technologies.</p>
      <h2>Conference topics</h2>
      <p>Think of the topics below as inspiration for talks. The list is by no means complete. Your presentation
        could be on one or more of these areas:</p>

      <ul>
        <li>Build and deployment</li>
        <li>Microservices - show us how you did it!</li>
        <li>SPA - get it into production</li>
        <li>Mobile</li>
        <li>Big data / NoSQL</li>
        <li>Cloud</li>
        <li>Plugins - creating plugins, advanced plugin creation, using specific plugins, useful plugins that peer
          developers should know about.
        </li>
        <li>Testing</li>
        <li>Functional Programming</li>
        <li>Real-world experiences - these could be 15, 30 or 60-minute presentations on anything significant you have
          developed, including challenges and unexpected benefits.
        </li>
        <li>Enterprise - Challenges and successes of integrating GR8 technologies with existing legacy, selling
          management on GR8 technologies.
        </li>
      </ul>

      <p>When you submit your talk, please tag your presentation with one or more of these tags, and add your own,
        if one is missing:</p>
      <ul>
        <li>Groovy</li>
        <li>Java</li>
        <li>JVM</li>
        <li>Spring Boot</li>
        <li>Spring Framework</li>
        <li>Grails</li>
        <li>Micronaut</li>
        <li>Quarkus</li>
        <li>Helidon</li>
        <li>Apache Camel</li>
        <li>Ratpack</li>
        <li>Spock Framework</li>
        <li>GEB</li>
        <li>Gradle</li>
        <li>Building Tools</li>
        <li>Continuous Integration</li>
        <li>Deployment Platforms</li>
        <li>Continuous Deployment</li>
        <li>Deployment Tools</li>
        <li>Android</li>
        <li>Griffon</li>
        <li>Testing</li>
        <li>Parallelization</li>
        <li>Meta programming</li>
        <li>Frontend</li>
        <li>Backend</li>
        <li>HTML5 / CSS3 / Javascript*</li>
        <li>AngularJS*</li>
        <li>ReactJS*</li>
        <li>VueJS*</li>
        <li>Vanilla Javascript*</li>
        <li>Cloud</li>
        <li>NoSQL</li>
        <li>Mobile</li>
        <li>In production / Use cases</li>
        <li>Security</li>
        <li>Integration</li>
      </ul>
      <p>* when relevant to the JVM</p>
      <h2>Workshop topics</h2>
      <p>We will have a number of workshops in parallel to the conference. If you have a subject that would fit into
        this format, please let us know!</p>
      <h2>Admission and travel</h2>
      <p>It is important for GR8Conf & JDK.io to keep the cost of travel and lodging to a minimum. Therefore, we hope
        your company/employer can cover your expenses. We will consider this as a partnership for the conference,
        and will place your company in the partnership program accordingly. For more information, please contact <MailTo
          email="sponsors@gr8conf.org"/></p>
      <h2>All speakers will receive:</h2>
      <ul>
        <li>Complimentary VIP pass to GR8Conf & JDK.io, including an invitation to speakers dinner.</li>
        <li>Up to three nights of stay at the hotel picked by the organizers.</li>
        <li>If your company cannot cover your expenses, you can expect up to €500 for European travel expenses to be
          arranged after proposal acceptance. To keep the budget manageable we ask that speakers be as flexible as
          possible with flights and airlines. Travel expenses from outside Europe will be determined on a case by case
          basis.
        </li>
      </ul>

      <h2>Presentation information</h2>
      <p>Presentations do not need to be provided or approved prior to speaking.</p>
      <p>We ask that a copy of the slidedeck be made available to the crew after the presentation, so that we can
        publish it online (Slideshare or alike)</p>
      <p>All presentations are subject to being recorded and can be made available to the public without further
        remuneration to the presenter. Do not include any proprietary material that cannot meet this
        requirement.</p>
      <h2>Notes on submitting a presentation</h2>
      <p>When submitting a presentation for consideration, it is important that you provide all requested
        information. The contact information you provide with your submission will be used for all future
        correspondence and should reflect a location where you can be reached throughout the conference planning
        period. Please notify us if your contact information changes. We will notify applicants of acceptance, using
        the information provided, according to the schedule above. When submitting a presentation, you also accept
        our <Link to='/code-of-conduct'>Conference Code of Conduct</Link>.</p>
      <h2>Where and when to submit</h2>
      <p>Please visit <ExternalLink link="https://cfp.gr8conf.org"/> where you
        can create your profile and submit your talks. The CFP closes on September 1st 2022. Thank you.</p>
      <h2>Questions?</h2>
      <p>If you have any questions, contact <MailTo email="info@gr8conf.org"/>.</p>

    </PagePanel>
  </Page>
);
