import {Component} from 'react'

class TawkIO extends Component {
    componentDidMount() {
        if(this.props.token) {
            window.Tawk_API = window.Tawk_API || {};
            window.Tawk_LoadStart = new Date();
            const s1 = document.createElement("script");
            s1.async = true;
            s1.src = 'https://embed.tawk.to/' + this.props.token + '/default';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            document.body.appendChild(s1);
        }
    }

    render() {
        return null;
    }
}

export default TawkIO;