import React, {Component} from 'react';
import {ExternalLink} from "../../components/helpers/EasyLinks";
import FA from "react-fontawesome";

export class SponsorView extends Component {

    render() {
        const {group, company, website, image, list, description, twitter, linkedIn} = this.props;
        const html = {__html: description};
        return <div className={group + (list ? '' : ' text-center')}>
            {!list && <h1>{group.name}</h1>}
            {image && <ExternalLink link={website}>
                <img className={(list ? 'pull-right ' : '') + "sponsor organizer"} src={image} alt={company}/>
            </ExternalLink>}
            {list && <h3>
                <ExternalLink link={website}>{company}</ExternalLink>
            </h3>}
            <div className={"text-left " + (list ? '' : 'lead')} dangerouslySetInnerHTML={html}/>
            {twitter && <span><ExternalLink link={twitter}><FA name="twitter-square" size="lg"/></ExternalLink> </span>}
            {linkedIn && <span><ExternalLink link={linkedIn}><FA name="linkedin-square" size="lg"/></ExternalLink> </span>}
        </div>
    }
}


