import {PRICES_HAS_ERRED, PRICES_IS_LOADING, PRICES_FETCHED_OK} from '../constants/prices'

export const prices = (state = [], action) => {
    switch (action.type) {
        case PRICES_FETCHED_OK:
            return action.prices;
        default:
            return state;
    }
};

export const pricesHasErred = (state = false, action) => {
    switch (action.type) {
        case PRICES_HAS_ERRED:
            return action.hasErred;

        default:
            return state;
    }
};

export const pricesIsLoading = (state = false, action) => {
    switch (action.type) {
        case PRICES_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
};

