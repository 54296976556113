import React, {Component} from 'react';
import FA from 'react-fontawesome';

class LoadingDiv extends Component {


    render() {
        const styles = {
            loading: {
                position: 'relative',
                width: '100%',
                minHeight: '50px',
                height: '100%'
            },
            innerLoading: {
                position: 'absolute',
                height: '50px',
                left: '50%',
                width: '50px',
                textAlign: 'center'
            }
        };
        return <div style={styles.loading}>
            <div style={styles.innerLoading}><FA name="circle-o-notch" spin={true}/></div>
            {this.props.children}
        </div>
    }
}

export default LoadingDiv;