import React, {Component} from 'react';
import Speaker from "./SpeakerInfo"
import {Container, Row, Col} from 'react-bootstrap';


class SpeakersList extends Component {
    render() {
        return (
            <Container>
                <Row>
                    {this.props.speakers.map(speaker => (
                        <Col xs={12} sm={6} lg={6} key={speaker.id}>
                            <Speaker speaker={speaker}/>
                            <span></span>
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    }
}


export default SpeakersList;

