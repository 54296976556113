import React, {Component} from 'react';
import {connect} from "react-redux";
import {Page, PagePanel} from "../../components/helpers/PagePanel";
import {AgendaPanel} from "../../components/agenda/AgendaPanel";
import {Disclaimer} from "../../components/disclaimer/Disclaimer";

class AgendaPage extends Component {


    render() {
        const {isLoading, agenda} = this.props;
        return <Page>
            <PagePanel title="Agenda">
                {isLoading && <h3 style={{paddingLeft: 20}}>Stand by while loading the awesome agenda....</h3>}
                {!isLoading && <div>
                    <AgendaPanel agenda={agenda}/>
                    <Disclaimer/>
                </div>}
            </PagePanel>

        </Page>
    }
}

const mapStateToProps = state => {
    return {agenda: state.agenda, isLoading: state.agendaIsLoading};
};

export default connect(mapStateToProps)(AgendaPage);
