import React, {Component} from 'react';
import ImageGallery from 'react-image-gallery'
import './BeersGallery.css'

import image1 from './the-beers.jpg';
import image1Thumb from './the-beers-thumb.jpg';
import image2 from './instructions.jpg';
import image2Thumb from './instructions-thumb.jpg';
import image3 from './malt-syrup.jpg';
import image3Thumb from './malt-syrup-thumb.jpg';
import image4 from './brewing.jpg';
import image4Thumb from './brewing-thumb.jpg';
import image5 from './tasting.jpg';
import image5Thumb from './tasting-thumb.jpg';
import image6 from './the-bottles.jpg';
import image6Thumb from './the-bottles-thumb.jpg';
import image7 from './foam.jpg';
import image7Thumb from './foam-thumb.jpg';
import image8 from './groovy-ale.jpg';
import image8Thumb from './groovy-ale-thumb.jpg';
import image9 from './400-bottles-of-beer.jpg';
import image9Thumb from './400-bottles-of-beer-thumb.jpg';
import {Col, Container, Row} from "react-bootstrap";

class BeersGallery extends Component {
    constructor(props) {
        super(props);
        this.images = [
            {
                original: image1,
                thumbnail: image1Thumb,
                description: "The 2014 lineup of GR8Conf Beers"
            },
            {
                original: image2,
                thumbnail: image2Thumb,
                description: "Getting introduced to the recipes."
            },
            {
                original: image3,
                thumbnail: image3Thumb,
                description: "Pouring the malt syrup"
            },
            {
                original: image4,
                thumbnail: image4Thumb,
                description: "Boiling the brew"
            },
            {
                original: image5,
                thumbnail: image5Thumb,
                description: "First tasting of the final result"
            },
            {
                original: image6,
                thumbnail: image6Thumb,
                description: "Preparing bottles"
            },
            {
                original: image7,
                thumbnail: image7Thumb,
                description: "Good foam"
            },
            {
                original: image8,
                thumbnail: image8Thumb,
                description: "Groovy Ale bottled and ready"
            },
            {
                original: image9,
                thumbnail: image9Thumb,
                description: "Close to 400 bottles of #GR8Beer"
            },


        ];
    }

    render() {

        return (
            <Container>
                <Row>
                    <Col xs={12} smPush={1} sm={10} mdPush={2} md={8}>
                        <ImageGallery items={this.images} thumbnailPosition='top' autoPlay slideDuration={800} slideInterval={5000}/>
                    </Col>
                </Row>
            </Container>
        );
    }

}

export default BeersGallery;
