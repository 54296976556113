import React, {Component} from "react";
import FA from 'react-fontawesome';
import PropTypes from "prop-types";
import moment from "moment/moment";
import {sameMinute, timeAsNumber} from "../helpers/DateHelpers";

/**
 * Renders the hour lines and the red line indicating the current time, if day is in scope (TBD).
 */
export class HourLines extends Component {
    static propTypes = {
        day: PropTypes.object.isRequired,
        hourHeight: PropTypes.number.isRequired,
        hours: PropTypes.arrayOf(PropTypes.number.isRequired)
    };

    constructor(props) {
        super(props);
        this.state = HourLines.makeState(props);
        this.updateCurrentTime = this.updateCurrentTime.bind(this)
    }

    componentWillReceiveProps(newProps, nextContext) {
        this.setState(HourLines.makeState(newProps));
    }

    static makeState(props) {
        return {
            currentTimeStyle: {display: 'none'},
            currentTimePos: 0,
            day: props.day,
            hourHeight: props.hourHeight,
            firstHour: props.hours[0],
            lastHour: props.hours[props.hours.length - 1],
        };
    }

    componentDidMount() {
        this.timerID = setInterval(() => this.updateCurrentTime(), 10000);
        setTimeout(() => this.updateCurrentTime(), 500);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    updateCurrentTime() {
        const now = moment();
        if (!sameMinute(this.state.now, now)) {

            const top = (timeAsNumber(now) - this.state.firstHour)* this.state.hourHeight;
            // TODO Only display when day.date is current date
            const hour = now.hour();
            const display = hour >= this.state.firstHour && hour < this.state.lastHour ? 'inherit' : 'none';
            this.setState({
                now: now,
                currentTimeStyle: {top: top, display: display}
            });
        }
    }

    render() {
        const {currentTimeStyle} = this.state;
        const {hourHeight} = this.props;
        return <div className="hour-Container">
            <div className="current-time time-cell" style={currentTimeStyle}>
                <div className="time">
                    <FA name="clock-o"/>
                </div>
            </div>
            {this.props.hours.map(hour => (
                <div className="time-cell" key={hour} style={{height: hourHeight}}>
                    <div className="time">{hour}:00</div>
                </div>))}
        </div>
    }
}
