import React, {Component} from 'react';
import { PagePanel } from '../../../components/helpers/PagePanel';
import '../Home.css'
import priceImage from '../../../assets/images/copenhagen-silhouette.jpg';
import Prices from "../../../components/prices/PricesTable";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import OCITraining from "../../../components/prices/OCI";
import {ociTraining} from "../../../config";

export class Pricing extends Component {
    render() {
        return <PagePanel className="prices" bgImage={priceImage}>
            <h1>Pricing</h1>
            <Prices/>
            <Container>
                <Row className="text-center">
                  {ociTraining && <Col xs={9}>
                        <OCITraining/>
                    </Col>}
                    <Col xs={9}>
                        <p><small className="text-info">Terms and conditions apply. For more information see the <Link to="/registration">Registration</Link> page.</small></p>
                    </Col>
                </Row>

            </Container>
        </PagePanel>
    }
}

