
export const config = process.env;
console.log(config)
export const conferenceId = (config.REACT_APP_CONFERENCE_ID);
export const showRegistration = (config.REACT_APP_SHOW_REGISTRATION === "true");
export const showSpeakers = (config.REACT_APP_SHOW_SPEAKERS === "true");
export const showCFP = (config.REACT_APP_SHOW_CFP === "true");
export const showAgenda = (config.REACT_APP_SHOW_AGENDA === "true");
export const showLogin = (config.REACT_APP_SHOW_LOGIN === "true");
export const showRatings = (config.REACT_APP_SHOW_RATINGS === "true");
export const showLeaderboard = (config.REACT_APP_SHOW_LEADERBOARD === "true");
export const registrationUrl = config.REACT_APP_REGISTRATION_URL;
export const speakersUrl = (config.REACT_APP_BASE_URL+'/api2/speakers/'+config.REACT_APP_CONFERENCE_ID);
export const talksUrl = (config.REACT_APP_BASE_URL + '/api2/talks/' + config.REACT_APP_CONFERENCE_ID);
export const tagsUrl = (config.REACT_APP_BASE_URL + '/api2/tags/' + config.REACT_APP_CONFERENCE_ID);
export const agendaUrl = (config.REACT_APP_BASE_URL + '/api2/agenda/' + config.REACT_APP_CONFERENCE_ID);
export const leaderboardUrl = (config.REACT_APP_BASE_URL + '/api/leaderboard/' + config.REACT_APP_CONFERENCE_ID);
export const oauthCodeUrl = (config.REACT_APP_BASE_URL + '/api/user/code');
export const oauthGetUserUrl = (config.REACT_APP_BASE_URL + '/api/user/get');
export const favoritesGetUrl = (config.REACT_APP_BASE_URL + '/api/favorites/get');
export const favoritesPutUrl = (config.REACT_APP_BASE_URL + '/api/favorites/put');
export const ratingsGetUrl = (config.REACT_APP_BASE_URL + '/api/ratings/get');
export const ratingsPutUrl = (config.REACT_APP_BASE_URL + '/api/ratings/put');
export const confRatingsGetUrl = (config.REACT_APP_BASE_URL + '/api/confrating/get');
export const confRatingsPutUrl = (config.REACT_APP_BASE_URL + '/api/confrating/put');
export const pricesUrl = '/prices.json';
export const sponsorDataUrl = (config.REACT_APP_BASE_URL + '/api2/sponsors/' + config.REACT_APP_CONFERENCE_ID);
export const gaId = (config.REACT_APP_GA_ID);
export const oauthIoToken= (config.REACT_APP_OAUTH_IO);
export const tawkIoToken = (config.REACT_APP_TAWK_IO);
export const agendaApp = (config.REACT_APP_AGENDA_APP === "true");
export const ociTraining = (config.REACT_APP_OCI_TRAINING === "true");
