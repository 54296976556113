import React, {Component} from "react";
import ReactDOM from "react-dom";
import FA from 'react-fontawesome';
import PropTypes from "prop-types";
import moment from "moment/moment";
import ResizeAware from "react-resize-aware";
import {RenderSpeakersLinks} from "../speakers/SpeakersLink";
import {Link} from "react-router-dom";
import {HourLines} from "./HourLines";
import AgendaTrack from "./AgendaTrack";
import Favorite from "../talks/Favorite";
import Rating from "../talks/Ratings";
import {agendaApp} from "../../config";

const BreakRender = (slot) => {
    return <div className="break">
        <div className="break-icon"><FA name={slot.faIcon}/></div>
        <small>{slot.name}</small>
    </div>
};

const TalkRender = (slot) => {
    return <div className={"talk" + (slot.duration < 30 ? ' short' : '') + (slot.duration > 60 ? ' long' : '')}>
        {!agendaApp && <div className="pull-right favorite"><Favorite id={slot.talk.id}/>&nbsp;&nbsp;&nbsp;<Rating id={slot.talk.id} data={[]}/></div>}
        <div className="talk-hours">{slot.start}-{slot.end}</div>
        <div className="talk-title"><Link to={"/talks/" + slot.talk.id}>{slot.talk.title}</Link><span> </span></div>
        <div className="talk-by">(<RenderSpeakersLinks speakers={slot.speakers}/>)</div>
    </div>
};


export class AgendaDay extends Component {

    static propTypes = {
        day: PropTypes.object,
        hourHeight: PropTypes.number,
        simple: PropTypes.bool
    };
    static defaultProps = {
        hourHeight: 135,
        simple: false
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.makeState(props),
            width: 0,
            offset: 0
        };
        this.onResize = this.onResize.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(this.makeState(nextProps));
    }

    makeState(props) {
        const range = (start, end) => Array.from({length: (end - start)}, (v, k) => k + start);

        const day = props.day;
        if (!day) {
            return {
                hours: []
            }
        }
        const startOfDay = moment(day.day + ' ' + day.start).startOf('hour');
        const endOfDay = moment(day.day + ' ' + day.end).endOf('hour').add(1, 'second');
        const hoursInDay = endOfDay.hour() - startOfDay.hour();
        const height = hoursInDay * props.hourHeight;
        const hours = range(startOfDay.hour(), endOfDay.hour() + 1);
        const breakTrack = day.tracks.find(track => track.breaks);
        const spanTracks = day.tracks.filter(track => track.allColumns);
        const regularTracks = day.tracks.filter(track => !(track.breaks || track.allColumns));
        return {
            ...this.state,
            day: props.day,
            breakTrack: breakTrack,
            spanTracks: spanTracks,
            regularTracks: regularTracks,
            hourHeight: props.hourHeight,
            hours: hours,
            height: height,
            startOfDay: startOfDay,
            endOfDay: endOfDay,
        }
    }

    componentDidMount() {
        const node = ReactDOM.findDOMNode(this.refs.tracks);
        if (node) {
            this.setState({
                offset: node.offsetLeft
            });
        }
    }

    onResize = ({width}) => {
        const node = ReactDOM.findDOMNode(this.refs.tracks);
        this.setState({
            offset: node.offsetLeft,
            width: width,
        });

    };

    render() {
        if (!this.state.regularTracks) {
            return <div>Please wait for agenda data...</div>
        }
        const trackWidth = (this.state.width - this.state.offset) / this.state.regularTracks.length;
        return <ResizeAware
            style={{position: 'relative'}}
            onlyEvent
            onResize={this.onResize}
        >
            <div className="agenda-day" style={{height: this.state.height}}>
                <HourLines day={this.state.day} hours={this.state.hours} hourHeight={this.state.hourHeight}/>
                <div ref="tracks" className="tracks">
                    <AgendaTrack day={this.state.day}
                                 className='breaks'
                                 track={this.state.breakTrack}
                                 hourHeight={this.state.hourHeight}
                                 width={this.state.width - this.state.offset + 6}
                                 offset={0}
                                 slotRender={BreakRender}
                                 simple={this.props.simple}/>
                    {this.state.spanTracks.map(track => (
                        <AgendaTrack key={track.id}
                                     className='allColumns'
                                     day={this.state.day}
                                     track={track}
                                     hourHeight={this.state.hourHeight}
                                     width={this.state.width - this.state.offset}
                                     offset={0}
                                     slotRender={TalkRender}
                                     simple={this.props.simple}/>
                    ))}
                    {this.state.regularTracks.map((track, index) => {
                        const offset = trackWidth * index;
                        return <AgendaTrack key={track.id}
                                            day={this.state.day}
                                            track={track}
                                            hourHeight={this.state.hourHeight}
                                            width={trackWidth}
                                            offset={offset}
                                            slotRender={TalkRender}
                                            simple={this.props.simple}/>
                    })}
                </div>
            </div>
        </ResizeAware>

    }
}
