import React, {Component} from "react";
import PropTypes from "prop-types";
import {timeAsNumber} from "../helpers/DateHelpers";
import {connect} from "react-redux";
import {getTalkIdsFromTags} from "../tags/TagHelper";

class AgendaTrack extends Component {
    static propTypes = {
        className: PropTypes.any,
        day: PropTypes.object.isRequired,
        hourHeight: PropTypes.number.isRequired,
        track: PropTypes.object.isRequired,
        width: PropTypes.number.isRequired,
        offset: PropTypes.number.isRequired,
        slotRender: PropTypes.func.isRequired,
        simple: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = AgendaTrack.makeState(props);
    }

    componentWillReceiveProps(newProps) {
        this.setState(AgendaTrack.makeState(newProps));
    }

    // Calculate offset based on track start time vs day start time
    static makeState(props) {

        return {
            day: props.day,
            hourHeight: props.hourHeight,
            width: props.width,
            offset: props.offset,
            taggedTalks: getTalkIdsFromTags(props.tags.active),
            ...props.track
        };
    }

    render() {
        const renderSlot = (slot) => {
            const duration = slot.duration / 60;
            const height = duration * this.state.hourHeight;
            const short = height < 30;
            const top = (timeAsNumber(slot.startTime) - timeAsNumber(this.state.day.startTime)) * this.state.hourHeight;
            const width = this.state.width;
            let highlight = '';
            const taggedTalks = this.state.taggedTalks;
            if (slot.talk && taggedTalks.length > 0) {
                highlight = taggedTalks.findIndex(id => id === slot.talk.id) === -1 ? ' dim' : ' highlight';
            }

            return <div className={"slot" + highlight + (short ? ' short':'') + (this.props.simple ? ' simple' : '')} key={slot.uid}
                        style={{top: top, left: this.state.offset, height: height, width: width, backgroundColor: this.state.color}}>
                {this.props.slotRender(slot, this.state.taggedTalks)}
            </div>

        };
        const className = "track " + (this.props.className ? this.props.className : '');

        return <div className={className}>
            <div className="track-header" style={{left: this.state.offset, width: this.state.width}}>
                {this.state.name}
            </div>
            {this.state.slots.map(slot => (renderSlot(slot)))}
        </div>
    }
}

const mapStateToProps = state => {
    return {tags: state.tags};
};

export default connect(mapStateToProps)(AgendaTrack);
