import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import store from "./data/store";
import {agendaApp} from "./config";
import AgendaApp from "./AgendaApp";

ReactDOM.render(
  <Provider store={store}>
    <div>
      {!agendaApp && <App/>}
      {agendaApp && <AgendaApp/>}
    </div>
  </Provider>,
  document.getElementById('root'));
// registerServiceWorker();
