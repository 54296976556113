import {conferenceId} from "../config";

export const getUserData = (getState, extraData = {}) => {
    const {status, uuid} = getState().oauth.user;
    if (status !== 200  || !uuid) {
        return undefined
    }
    const data = {...extraData};
    delete data['conference'];
    return {"conference.id": conferenceId, uuid: uuid, ...data};
};