import React, {Component} from 'react';
import 'oauthio-web'
import {connect} from "react-redux";
import {clearOauthUser, getOAuthUser} from "../data/actions/user";
import {oauthIoToken} from "../config";
import {Button, Col, Container, Form, Modal, NavDropdown, NavItem, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {ExternalLink} from "../components/helpers/EasyLinks";
import oauth from "./oauth.png";
import FA from 'react-fontawesome';
import 'bootstrap-social/bootstrap-social.css'
import {FieldGroup} from "../components/helpers/FieldGroup";

const OAuth = window.OAuth;


class TwitterInfoModal extends Component {
    static propTypes = {
        onSubmitUser: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            user: props.user
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        const user = {...this.state.user, [name]: value};
        this.setState({
            user: user
        });
    };

    submitData = () => {
        this.props.onSubmitUser(this.state.user);
    };

    render() {
        return <Modal show={true} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Twitter login requires more information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Since the Twitter API does not provide your email and real name, we ask you to enter it here:</p>
                <div>
                    <Form horizontal>
                        <FieldGroup
                            id="name"
                            type="text"
                            label="Your Name"
                            placeholder="Enter your full name"
                            onChange={this.handleChange}
                        />
                        <FieldGroup
                            id="email"
                            type="email"
                            label="Your Email Address"
                            placeholder="Enter your valid email address"
                            onChange={this.handleChange}
                        />
                    </Form>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.submitData}>Submit</Button>
                <Button onClick={this.props.closeModal}>Close</Button>
            </Modal.Footer>

        </Modal>
    }

}


class UserProviderModal extends Component {

    static propTypes = {
        onSelectProvider: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired
    };

    render() {
        const handleProvider = (provider) => () => {
            this.props.onSelectProvider(provider)
        };

        return <Modal show={true} onHide={this.props.closeModal} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Login
                    sponsor: <ExternalLink link="https://oauth.io"><img src={oauth} alt="Oauth.IO"/></ExternalLink></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Please select your favorite login provider:</h4>
                <Container fluid>
                    <Row>
                        <Col xs={12} md={4}>
                            <Button className="btn-primary btn-lg btn-social btn-twitter" onClick={handleProvider('twitter')}><FA
                                name="twitter"/> Twitter</Button>
                        </Col>
                        <Col xs={12} md={4}>
                            <Button className="btn-primary btn-lg btn-social btn-google" onClick={handleProvider('google')}><FA
                                name="google"/> Google</Button>
                        </Col>
                        <Col xs={12} md={4}>
                            <Button className="btn-primary btn-lg btn-social btn-github" onClick={handleProvider('github')}><FA
                                name="github"/> GitHub</Button>
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                </Container>
                <p style={{paddingTop: 10}}><em>Note: </em>Login requires a popup window. Please allow it to run!</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.props.closeModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    }
}

class User extends Component {
    constructor(props) {
        super(props);

        this.showLoginPopup = this.showLoginPopup.bind(this);
        this.onClickLogin = this.onClickLogin.bind(this);
        this.onClickLogout = this.onClickLogout.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.submitTwitterInfo = this.submitTwitterInfo.bind(this);
        this.closeTwitterModal = this.closeTwitterModal.bind(this);

        this.state = {
            showModal: false,
            oauthRequest: {}
        }
    }

    componentDidMount() {
        OAuth.initialize(oauthIoToken);
    }


    componentWillReceiveProps(nextProps, nextContext) {
        const {oauth} = nextProps;
        this.setState((state) => ({...state, ...oauth}))
    }

    showLoginPopup(provider) {
        const code = this.props.oauth.code;
        const {getOAuthUser, onClick} = this.props;
        const closeModal = this.closeModal;

        //Using popup
        OAuth.popup(provider, {state: code})
            .done((result) => {
                const oauthRequest = {state: code, code: result.code, provider: provider};
                getOAuthUser(oauthRequest);
                this.setState({...this.state, oauthRequest: oauthRequest})
            })
            .fail((err) => console.log(err))
            .always(() => {
                closeModal()
            });
        onClick();
    }

    onClickLogin() {
        this.setState({showModal: true});
    }

    closeModal() {
        setTimeout(() => this.setState({showModal: false}), 10);
    }

    closeTwitterModal() {
        setTimeout(() => this.setState({needTwitterInfo: false}), 10);
    }

    submitTwitterInfo(user) {
        const oauthRequest = {...user, ...this.state.oauthRequest};
        const {getOAuthUser} = this.props;
        getOAuthUser(oauthRequest);
    }

    onClickLogout() {
        const {clearOauthUser, onClick} = this.props;
        clearOauthUser();
        onClick();
    }

    render() {

        const {fetchingCode, user, isLoggedIn, code, showModal, needTwitterInfo} = this.state;
        const {eventKey} = this.props;
        if (!fetchingCode && code) {
            if (!isLoggedIn) {
                return (
                    <NavItem eventKey={eventKey} onClick={this.onClickLogin}>
                        Login
                        {!needTwitterInfo && showModal &&
                            <UserProviderModal onSelectProvider={this.showLoginPopup} closeModal={this.closeModal}/>}
                        {needTwitterInfo &&
                            <TwitterInfoModal onSubmitUser={this.submitTwitterInfo} closeModal={this.closeTwitterModal} user={user} code={code}/>}
                    </NavItem>
                )
            } else if (isLoggedIn) {
                return (
                    <NavDropdown eventKey={eventKey} id="user" title={(
                        <img src={user.profileImageUrl} alt={user.name} style={{maxHeight: 20}}/>)}>

                        <NavItem eventKey={eventKey + '.1'}>{user.name}</NavItem>
                        <NavItem eventKey={eventKey + '.2'} onClick={this.onClickLogout}>Logout</NavItem>
                    </NavDropdown>
                )
            }

        } else {
            return null
        }

    }
}

const mapStateToProps = (state) => {
    return {
        oauth: state.oauth,
    };
};
const mapDispatchToProps = {
    getOAuthUser,
    clearOauthUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(User);

