import {SPEAKERS_HAS_ERRED, SPEAKERS_IS_LOADING, SPEAKERS_FETCHED_OK, FEATURED_SPEAKERS_FETCHED_OK} from '../constants/speakers'

export const speakers = (state = [], action) => {
    switch (action.type) {
        case SPEAKERS_FETCHED_OK:
            return action.speakers;
        default:
            return state;
    }
};

export const speakersById = (state = [], action) => {
    switch (action.type) {
        case SPEAKERS_FETCHED_OK:
            return Object.assign({}, ...action.speakers.map(speaker => ({[speaker.id]: speaker})));
        default:
            return state;
    }
};

export const featuredSpeakers = (state = [], action) => {
    switch (action.type) {
        case FEATURED_SPEAKERS_FETCHED_OK:
            return action.featuredSpeakers;
        default:
            return state;
    }
};

export const speakersHasErred = (state = false, action) => {
    switch (action.type) {
        case SPEAKERS_HAS_ERRED:
            return action.hasErred;

        default:
            return state;
    }
};

export const speakersIsLoading = (state = false, action) => {
    switch (action.type) {
        case SPEAKERS_IS_LOADING:
            return action.isLoading;

        default:
            return state;
    }
};

